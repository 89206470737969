$side-padding: 70px;

.NoMetamaskWall,
.InvalidNetworkWall {
  min-width: 350px !important;
  max-width: 770px !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding: 40px $side-padding;
  background-color: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  &-title {
    font-family: Lato;
    font-size: 30px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 35px;
  }
  &-sub-title-purple {
    font-family: Lato;
    font-size: 20px;
    line-height: 28px !important;
    font-weight: 700;
    text-align: center;
    color: #56488c;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  &-actions {
    text-align: center;
    margin-bottom: 20px;
  }
  &-description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #4a4a4a;
  }
  &-switchNetwork {
    display: flex;
    justify-content: center;
  }
  &-notes {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: #4a4a4a;
  }
  &-link {
    color: #0085ff;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  &-information {
    margin-top: 20px;
    background-color: #f1f0f6;
    margin-left: -$side-padding;
    margin-right: -$side-padding;
    padding: 20px $side-padding 40px $side-padding;
    &-label {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: #4a4a4a;
      margin-bottom: 8px;
      &:not(:first-child) {
        margin-top: 8px;
        margin-bottom: 0px;
      }
    }
    p {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #4a4a4a;
    }
  }
  &-iframe-video {
    width: 340px;
  }
  @media only screen and (max-width: 570px) {
    &-iframe-video {
      width: calc(100% + 25px);
    }
  }
}
