$inputWidth: 225px;

.OptionsFilter {
  display: flex;
  &-label {
    margin-right: 8px;
    font-family: Lato;
    font-size: 14px;
    line-height: 14px;
    color: #898a8d;
  }
  &-option {
    &-active {
      display: flex;
      font-weight: bold;
      > img {
        margin-left: 16px;
        margin-right: 8px;
      }
    }
  }
  margin-bottom: 16px;

  .ui.floating.dropdown > .menu {
    box-shadow: none !important;
    border: 1px solid #bbb6d1;
  }
  .ui.button.dropdown .menu {
    box-shadow: none !important;
    margin-top: 8px;
  }
  .ui.button:hover {
    filter: none;
  }
  .ui.dropdown .menu > .header {
    font-size: 12px;
    font-weight: 400;
    color: #898a8d;
    text-transform: none;
  }
}

.PageFilters {
  z-index: 20;
  background-color: white;
  margin: -2em -2.5em 0px -2.5em;
  width: calc(100% + 5em);
  padding: 16px 2.5em;
  display: flex;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-clear-link {
    color: #0085ff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.58px;
    margin-left: 8px;
    margin-bottom: 8px;
    &:hover {
      filter: brightness(0.9);
      cursor: pointer;
    }
  }
  .CustomCheckboxTree {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .CustomCheckboxTree.active .CustomCheckboxTree-Dropdown-wrapper .CustomCheckboxTree-Dropdown,
  .ui.input,
  .ui.input > input {
    width: $inputWidth;
  }
  .SearchInput {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .search.icon {
    color: #0085ff;
  }
  .ui.checkbox {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  margin-bottom: 16px;
}
