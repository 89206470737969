button {
  &.is-unstyled {
    background: none !important;
    color: inherit !important;
    border: none !important;
    padding: 0 !important;
    font: inherit !important;
    cursor: pointer !important;
    outline: inherit !important;
  }

  &.is-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-family: arial, sans-serif;
    display: inline;
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.is-colored {
    color: $brandBlue;
  }
}
