.Toastify__toast-container {
  z-index: 100;
  position: absolute;
  width: 100%;
  padding: 0px;
  margin: -1em;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast--success {
  .Toastify__toast-body::before {
    content: "";
    background-size: 19px 19px;
    display: inline-block;
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
    margin-right: 10px;
  }
}

.Toastify__toast--warning {
  .Toastify__toast-body::before {
    background-image: url("./../../assets/warning-icon.svg");
  }
}
.Toastify__toast--error {
  .Toastify__toast-body::before {
    background-image: url("./../../assets/error-icon.svg");
  }
}
.Toastify__toast--success {
  .Toastify__toast-body::before {
    background-image: url("./../../assets/approved-icon.svg");
  }
}

.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast--success {
  background-color: #383154 !important;
  border-radius: 0;
  padding: 0 35px;
}

.Toastify__toast-body {
  display: flex;
  padding: 6px 0;
  font-family: Lato;
  font-size: 16px;
  color: white !important;
}

.Toastify__close-button {
  align-self: center;
  & > svg {
    width: 22px;
    height: 22px;
    color: white;
  }
}
