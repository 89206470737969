.CBCExpertVoteForm {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  &-description {
    color: #4a4a4a;
    margin-bottom: 8px;
  }
  textarea {
    resize: none !important;
    height: 55px !important;
    max-height: 55px !important;
  }
  .ui.button.SignatureButton {
    width: auto;
  }
}
