.CBCMemberExpertOnboardCard {
  width: 32.1%;
  max-width: 32.1%;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  &-header {
    background-color: #0070d2;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    padding: 5px 16px;
    color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &-body {
    background-color: white;
    padding: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    &-name {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: #000000;
    }
    &-sub-name {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-label {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #4a4a4a;
      }
      .ui.button {
        padding: 12px 16px;
        font-size: 12px;
        border-radius: 6px;
      }
    }
  }
}
