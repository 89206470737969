.text-font-size {
  font-size: 1.14285714rem;
}

.PadBottom0 {
  padding-bottom: 0px !important;
}

.PadTop0 {
  padding-top: 0px !important;
}

.PadRight9 {
  padding-right: 9px !important;
}

.LineHeight0 {
  line-height: 0px !important;
}

.noBorder {
  border: none !important;
}

.noMargins {
  margin: 0px !important;
}

.noPaddings {
  padding: 0px !important;
}

.noShadowBttn.ui.button.basic {
  box-shadow: none !important;
  margin-right: 0px;
  padding: 0px;
  &:hover {
    box-shadow: none !important;
  }
}

.noShadowBttn.ui.button {
  box-shadow: none !important;
  margin-right: 0px;
  padding: 0px;
  &:hover {
    box-shadow: none !important;
  }
}

.ComponentHeader {
  display: flex;
  font-family: Lato;
  font-size: 22px;
  font-weight: 600;
  color: #5c5c5c;
  margin-bottom: 20px;
  justify-content: space-between;
  column-gap: 8px;
  align-items: center;

  &-extra {
    font-size: 12px;
    line-height: 22px;
    color: #898a8d;
    margin-top: 7px;
  }
}

.AvatarPicture {
  min-height: 42px !important;
  min-width: 42px !important;
  max-height: 42px !important;
  max-width: 42px !important;
}

.AvatarPicture55 {
  min-height: 55px !important;
  min-width: 55px !important;
  max-height: 55px !important;
  max-width: 55px !important;
}
.AvatarPicture79 {
  min-height: 79px !important;
  min-width: 79px !important;
  max-height: 79px !important;
  max-width: 79px !important;
}

.CursorOnHover {
  &:hover {
    cursor: pointer;
  }
}
