.AdminExpertDetailsTab {
  &-title {
    display: inline;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #4a4a4a;

    &-dot {
      margin: 0 10px;
    }
  }

  &-info {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #56488c;
  }

  &-NoAssignedSeconders {
    height: 117px;
    background: #f1f0f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    & > img {
      width: 48px;
      height: 43px;
      margin-bottom: 8px;
    }

    &-description {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #56488c;
    }
  }

  &-NoVolunteersSeconders {
    display: flex;
    height: 57px;
    align-items: center;

    & > img {
      margin-right: 30px;
      width: 57px;
      height: 57px;
    }

    &-description {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #56488c;
    }
  }
}
