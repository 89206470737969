.CreateInviteModal {
  display: flex;
  flex-wrap: wrap;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: black;
  img {
    margin-left: auto;
    margin-right: auto;
    max-width: 64px;
    min-width: 64px;
    max-height: 64px;
    min-height: 64px;
    margin-bottom: 16px;
  }
  &-left-panel {
    text-align: center;
    padding: 64px 64px 78px 64px;
    width: 50%;
  }
  &-right-panel {
    text-align: center;
    padding: 64px 64px 78px 64px;
    width: 50%;
    border-top-right-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
    background-color: #f6f3f3;
  }
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #4a4a4a;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 767px) {
    &-left-panel {
      padding: 24px;
      width: 100%;
    }
    &-right-panel {
      padding: 24px;
      width: 100%;
    }
  }
}
