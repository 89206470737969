.PageContentTabs {
  display: flex;
  color: #898a8d;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  column-gap: 16px;
  margin-bottom: 16px;

  &-tab {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:not(:only-child) {
      &:hover {
        cursor: pointer;
      }
    }

    &:first-child {
      padding-left: 0px;
    }

    &.selected {
      color: #56488c;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &-vote-wrapper {
    display: flex;
  }

  &-vote-label.ui.label {
    display: flex;
    align-items: center;
    height: 40px;
    background: #e87b17;
    border-color: #e87b17;

    color: white;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;

    & img {
      margin-left: 17px;
      margin-right: 6px;
      width: 12px !important;
      height: 12px !important;
    }

    & span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $bodyBackgroundColor;
    }
  }
}
