$minWidth: 100px;
$maxWidth: 285px;
$maxWidthXL: 535px;

.CustomCheckboxTree {
  display: flex;
  flex-direction: column;

  font-family: Lato;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #5c5c5c;

  &-LabelGroup {
    margin-top: 8px;

    & .ui.label {
      background-color: #0085ff;
      color: white;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;

      & > img {
        display: inline-block;
        margin-left: 10px;
        width: 10px !important;
        height: 10px !important;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &-Label {
    font-weight: bold;
    color: black;
  }

  & .rct-collapse {
    display: none;
  }

  & .rct-node-icon {
    display: none;
  }

  & .rct-text > label {
    width: 100%;
  }

  & .rct-checkbox {
    vertical-align: sub;
  }

  & .rct-title,
  & .rct-checkbox {
    padding: 0 3px;
    width: 17px;
    height: 17px;
  }

  .checkbox-checked,
  .checkbox-half-checked,
  .checkbox-unchecked {
    width: 17px;
    height: 17px;
  }

  .checkbox-checked {
    background: url("../../assets/checkbox-checked.svg");
  }

  .checkbox-half-checked {
    background: url("../../assets/checkbox-half-checked.svg");
  }

  .checkbox-unchecked {
    background: url("../../assets/checkbox-unchecked.svg");
  }

  & .CustomCheckboxTree-Dropdown-wrapper {
    min-width: $minWidth;
    max-width: $maxWidth;

    & .CustomCheckboxTree-Dropdown {
      & > div > ol > li > span .rct-title {
        font-weight: bold;
      }
    }

    & .ui.left.icon.input {
      &.white-input .search.icon {
        color: #0085ff;
      }

      &.blue-input .search.icon {
        color: white;
        opacity: 1 !important;
      }
    }

    & .CustomCheckboxTree-CustomInput {
      background-color: #0085ff;
      color: white;
      text-align: left;
      font-weight: 600;
      // Copy pasted styles to match the input styles
      width: 100%;
      padding-left: 2.67142857em !important;
      padding-right: 1em !important;
      vertical-align: top;
      font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
      margin: 0;
      outline: 0;
      -webkit-appearance: none;
      line-height: 1.44em;
      padding: 0.5657em 1em;
      font-size: 1em;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      -webkit-box-shadow: 0 0 0 0 transparent inset;
      box-shadow: 0 0 0 0 transparent inset;
      -webkit-transition: color 0.1s ease, border-color 0.1s ease;
      transition: color 0.1s ease, border-color 0.1s ease;

      & div {
        display: inline-block;
        background-color: #204c95;
        border-radius: 50%;
        text-align: center;
        padding: 0 6px;
      }
    }

    & .CustomCheckboxTree-CustomInput:hover {
      cursor: text;
    }
  }

  &.active {
    & .CustomCheckboxTree-Dropdown-wrapper {
      min-width: $minWidth;
      max-width: $maxWidth;

      & .ui.input > input {
        width: $maxWidth;
        border-color: #c7c7c7;
        border-bottom: none;
        padding-bottom: 10.5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      & .CustomCheckboxTree-Dropdown {
        position: absolute;
        width: $maxWidth;
        height: fit-content;
        max-height: 460px;
        overflow: auto;
        padding: 10px 10px 0 10px !important;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background: #ffffff;
        border: 1px solid #c7c7c7;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: none;
        padding-top: 1px;
      }
    }
  }

  &:not(.active) .CustomCheckboxTree-Dropdown {
    display: none;
  }
}

.react-checkbox-tree {
  font-size: 14px;
  padding-bottom: 50px;
}

.ProfileCheckboxTree {
  .react-checkbox-tree {
    .rct-checkbox {
      display: none;
    }
    .rct-text {
      label {
        display: flex;
      }
    }
  }
}
