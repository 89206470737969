.AdminUserProfileHeader {
  display: flex;

  &-image {
    width: 87px;
    height: 87px;
    margin-right: 40px;
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #5c5c5c;
    margin-bottom: 8px;
  }

  &-label {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #898a8d;
    word-break: break-word;
  }

  &-value {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
  }
}
