.TicketTeamSection {
  display: flex;
  column-gap: 32px;
  &-member {
    text-align: center;
    > img {
      margin-left: auto;
      margin-right: auto;
      min-height: 55px;
      max-height: 55px;
      min-width: 55px;
      max-width: 55px;
      margin-bottom: 8px;
    }
    &-name {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: #5c5c5c;
    }
    &-role {
      font-family: Lato;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #5c5c5c;
    }
  }
}
