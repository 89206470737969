.ui.modal.FeedbackModal {
  width: 434px;
  height: 537px;
  padding: 24px;

  .FeedbackModal-title {
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.5799999833106995px;
    text-align: left;
    color: #5c5c5c;
    margin-bottom: 24px;
  }

  .FeedbackForm {
    & > .field,
    & > button {
      margin-top: 24px;
    }
    textarea {
      resize: none;
    }
  }
}

@media only screen and (max-width: 570px) {
  .ui.modal.FeedbackModal {
    margin: 0px !important;
    width: 100% !important;
    height: 100% !important;
    textarea {
      width: 100%;
    }
  }
  .ui.dimmer {
    padding: 0px !important;
  }
}
