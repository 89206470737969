.ui.segment,
.ui.bottom.attached.segment.tab {
  border-radius: 0;
}

// Prevents semantic to put the required ´*´ on nested/subsequent components
// (like labels, checkboxes, etc) inside a required field.
.ui.form .required.field > *:not(:first-child)::after {
  content: none;
}

// HorizontalFields
.fields > .HorizontalFields {
  display: flex;

  & > .field:not(:last-child) {
    margin-right: 40px !important;
  }
}

.ui.cards > .card {
  margin-left: 2em;
}

.ui.grid.is-withoutGutters,
.is-withoutGutters {
  margin: 0em;
  padding: 1em;
}

.ui.form .error.message,
.ui.form .success.message,
.ui.form .warning.message {
  display: inherit;
}

.ui.modal.CustomClose > .close {
  color: #0085ff;
  top: 0;
  right: 0;
}

.ui.red.basic.pointing.label::before {
  z-index: 0;
}

.ui.primary.button,
.ui.blue.button {
  background-color: #0085ff;
  &:hover {
    background-color: #0085ff;
    filter: brightness(0.95);
  }
}
.ui.blue.basic.button {
  background-color: transparent;
}
.ui.button:hover {
  filter: brightness(0.95);
}

.ui.purple.button {
  background-color: #56488c !important;
  &:hover {
    background-color: #56488c !important;
    filter: brightness(0.95);
  }
}
.ui.purple.basic.button {
  background-color: transparent !important;
  color: #56488c !important;
  font-weight: bold;
  -webkit-box-shadow: 0 0 0 1px #56488c inset !important;
  box-shadow: 0 0 0 1px #56488c inset !important;
  &:hover {
    color: white !important;
    background-color: #56488c !important;
    border-color: #56488c !important;
  }
}

.ui.loader:after {
  border-color: #767676 transparent transparent !important;
}
