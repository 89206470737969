.ReceivedInviteCard,
.SentInviteCard {
  background-color: white;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 16px 24px;
  margin-bottom: 8px;
  min-width: 347px !important;
  &-top-section {
    display: flex;
    justify-content: space-between;
    &-title {
      font-family: Lato;
      font-size: 20px;
      color: #4a4a4a;
      margin-bottom: 8px;
    }
    &-right-labels {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-left: 8px;
      &-date {
        font-size: 12px;
        color: #898a8d;
        margin-right: 8px;
      }
      &-label {
        color: #56488c;
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }

  &-description {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    a {
      width: 100%;
    }
    &-info {
      display: flex;
      flex-direction: column;
      &-role {
        font-size: 14px;
        color: #4a4a4a;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
      &-date {
        font-size: 14px;
        color: #898a8d;
      }
    }
    &-bttn-wrapper {
      display: ruby;
    }
    button {
      margin-top: 4px !important;
      min-height: 38px !important;
      &:first-child {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

.StatusCircle {
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 12px;
  margin-top: 1px;
  &.green {
    background-color: #3ac358;
  }
  &.orange {
    background-color: #e87b17;
  }
  &.yellow {
    background-color: #f4cf0d;
  }
  &.dark-red {
    background-color: #ba1010;
  }
  &.black {
    background-color: #000;
  }
  &.grey,
  &.gray {
    background-color: #bbb6d1;
  }
  &.purple {
    background-color: #56488c;
  }
  &.teal {
    background-color: #29d9b9;
  }
  &.blue {
    background-color: #0070d2;
  }
}

.CreateInviteForm {
  margin-top: 64px;
  margin-left: 56px;
  margin-right: 64px;
  margin-bottom: 64px;
  color: #898a8d;

  h1 {
    color: #5c5c5c;
  }

  &-Form {
    textarea {
      resize: none !important;
      max-height: 100px !important;
    }
  }
}
