.DeniedRoleBadge {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 5px;

  & .DeniedRoleBadge-left {
    display: flex;
    justify-content: center;
    flex: 1;

    & .DeniedRoleBadge-img {
      width: 28px;
      height: 28px;
      max-width: 28px;
      max-height: 28px;
      border-radius: 50%;
    }
  }

  & .DeniedRoleBadge-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;

    & .DeniedRoleBadge-name {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: -0.58px;
      text-transform: uppercase;
      color: #4a4a4a;
    }

    & .DeniedRoleBadge-type {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 5px;
      color: #898a8d;

      & img {
        margin-left: 2px;
        width: 15px;
        height: 15px;
        max-width: 15px;
        max-height: 15px;
      }
    }
  }

  & .DeniedRoleBadge-right {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  & .ui.placeholder .line {
    background: #f8f8f8;
    margin-top: 1px;
    height: 5px;

    &:first-child {
      height: 0.01px;
    }
  }
}
