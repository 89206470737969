.wrapperx {
  margin: 10;
  position: relative;

  &:after {
    content: "";
    background-color: #333;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}

@for $i from 1 through 36 {
  .form-loader:nth-child(#{$i}) {
    width: random($limit: 700) + px;
  }
}

.form-loader {
  background-color: rgb(211, 211, 211);
  margin-top: 15px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
  height: 15px;
  max-height: 15px !important;
  display: block;
  overflow: hidden;
}

// Loader animation
@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
