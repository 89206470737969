.VoteBars {
  width: 100%;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .Bar-Row {
    width: 100%;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    column-gap: 4px;
  }
  .Bar {
    max-width: 70%;
    border-radius: 8px;
  }
  .Bar-Label {
    color: #4a4a4a;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #6d6d6d;
  }
}
