.blueCircle {
  display: inline-block;
  background-color: rgb(61, 143, 175);
  color: white;
  border: 1px solid rgb(61, 143, 175);
  border-radius: 20px;
  width: 28px;
  text-align: center;
  line-height: 180%;
  margin-right: 6px;
  &.Reverse {
    background-color: white;
    color: black;
  }
}

.redCircle {
  display: inline-block;
  background-color: rgb(243, 66, 54);
  color: white;
  border: 1px solid rgb(243, 66, 54);
  border-radius: 20px;
  width: 28px;
  text-align: center;
  line-height: 180%;
  margin-right: 6px;
  &.Reverse {
    background-color: white;
    color: black;
  }
}
