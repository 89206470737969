.TicketDenyList {
  padding: 20px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;

  &-header {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: -0.58px;
    color: #5c5c5c;
    margin-bottom: 25px;
  }

  &-subheader {
    font-size: 14px;
    color: #898a8d;
    margin-bottom: 5px;
  }

  &-roleBadges {
    display: grid;
    gap: 7px;
  }
}
