.MarketplaceCard {
  width: 32%;
  border: 1px solid #dddddd;
  padding: 24px 24px 16px 24px;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  &-wrapper {
    display: flex;
    column-gap: 8px;
  }
  &-follow-icon {
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
    margin-left: 10px;
  }
  &-top-section {
    width: 100%;
    display: flex;
  }

  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    color: #000000;
  }
  &-sub-title {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
  }
  &-description {
    display: flex;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: #898a8d;
  }
  &-divider {
    margin: 12px 0px;
    width: 100%;
    border: 1px solid #dddddd;
  }
  &-bottom-container {
    display: flex;
    justify-content: space-between;
    &-info {
      color: #56488c;
      font-family: Lato;
      font-size: 12px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .MarketplaceCard {
    width: 48%;
  }
}

@media only screen and (max-width: 570px) {
  .MarketplaceCard {
    width: 100%;
  }
}
