$color: #29d9b9;
.FeedbackButton {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background: none;
  border: 1px solid $color;
  border-radius: 4px;

  width: 106px;
  height: 30px;
  margin-right: 14px;
  color: $color;
  transition: 0.25s;

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: $color;
    box-shadow: 0 0.5em 0.5em -0.4em $color;
    transform: translateY(-2px);
  }

  & i {
    margin-left: 9px;
  }
}
