.ExpertOnboardEvaluationDetails {
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  &-sub-title {
    display: flex;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    align-items: center;
    column-gap: 8px;
  }
  &-question {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #898a8d;
  }
  &-answer {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #898a8d;
    &-rate {
      font-size: 12px;
      padding: 4px 10px;
      background-color: #efefef;
      color: #4a4a4a;
    }
  }
  &-label {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #898a8d;
    margin-bottom: 4px;
  }
  li {
    list-style: none;
    margin-top: 8px;
  }
}
