.PreselectionVoteCollapsible {
  background: #e6e4ee;
  border-radius: 5px;
  min-height: 52px;
  color: #4a4a4a;
  margin-bottom: 14px;
  &.owner {
    filter: brightness(0.9);
  }

  &.active {
    filter: brightness(1);
    background: #bbb6d1;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &-detailsCol {
    display: flex !important;
    align-items: center;
    justify-content: center;

    & > img {
      transform: rotate(90deg);
      margin-left: 20px !important;
      min-width: 8px;
      min-height: 14px;
      max-width: 8px;
      max-height: 14px;
      margin-top: 2px;
    }

    .PreselectionVoteCollapsible.active & > img {
      transform: rotate(-90deg);
    }
  }

  &-memberCol {
    display: flex !important;
    &-name {
      align-self: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #4a4a4a;
    }
  }

  &-commentCol {
    div {
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    span {
      color: #0085ff;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  &-scoreCol {
    display: flex !important;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
  }

  &-dateCol {
    color: #898a8d;
  }

  &-repliesCol {
    display: flex !important;
    align-items: center;
    color: #0085ff;

    & > span {
      display: flex !important;
      border: 1px solid #0085ff;
      border-radius: 6px;
      padding: 8px 12px;
    }

    & img {
      width: 15px;
      height: 14px;
      margin-left: 10px;
      align-self: center;
    }

    &.no-replies {
      color: #898a8d;
    }
    .PreselectionVoteCollapsible.active {
      span {
        color: white;
      }
    }
  }

  &-dropdown {
    margin-bottom: 16px !important;
    padding-left: 50px !important;

    &-header {
      font-weight: bold;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.58px;
    }

    &-subheader {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      margin: 16px 0 0 0;
    }

    &-question {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 1px solid #bbb6d1;
      column-gap: 8px;

      &-score {
        display: flex;
        justify-content: flex-end;
        min-width: 42px;
        font-weight: bold;
        align-items: center;

        & img {
          width: 18px;
          height: 18px;
          margin-left: 8px;
        }

        & img:hover {
          cursor: pointer;
        }
      }
    }
  }
}
