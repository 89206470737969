.CommunityResources {
  &-link {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #0085ff;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  &-top-section {
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    color: #898a8d;
  }
  &-cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px;
  }
  &-imageContainer {
    display: flex;
    height: 120px;
  }
  &-card {
    min-width: 276px;
    max-width: 276px;
    min-height: 346px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    padding: 8px 24px 32px 24px;
    &-image {
      align-self: center;
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
    &-title {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      color: #4a4a4a;
      margin-bottom: 4px;
    }
    &-description {
      margin-bottom: 8px;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #4a4a4a;
    }
    .ui.purple.button {
      background-color: #56488c;
    }
  }
}
