.TicketsCard,
.BidCard {
  border-radius: 5px 5px 0px 0px;
  background-color: white;
  margin-top: 15px;
  padding: 20px 20px;
  min-height: 100px;
  min-width: 350px;
  border: 0.5px solid #d4d4d5;
  &.draft {
    background-color: #ebebeb;
    border-radius: 5px;
  }
  .top-section {
    display: flex;
    justify-content: space-between;
    &-title {
      color: #5c5c5c;
      display: flex;
      font-size: 19px;
      font-weight: 600;
      &:hover {
        filter: brightness(0.8);
        cursor: pointer;
      }
    }
    &-labels {
      display: flex;
      &-date {
        min-width: 30px;
        color: #898a8d;
        margin-left: 10px;
        font-size: 11px;
        font-weight: 600;
      }
      &-edit {
        color: #0085ff;
        margin-left: 10px;
        font-weight: 600;
        &:hover {
          filter: brightness(0.8);
          cursor: pointer;
        }
      }
    }
  }
}
.ActionSection {
  min-width: 350px;
  background-color: white;
  padding: 0px 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: 0.5px solid #d4d4d5;
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  &-left-actions {
    display: flex;
    justify-content: flex-start;
    align-self: center;
  }
  &-right-actions {
    display: flex;
    justify-content: flex-end;
    align-self: center;

    & .button {
      margin-right: 0;
    }
  }
  &-action {
    margin-right: 20px;
  }
  &-spliter {
    width: 1px;
    border: 1px solid #898a8d;
    background: #898a8d;
    margin-right: 20px;
  }
  &-details-link {
    color: #0085ff;
    cursor: pointer;
  }
}

.priority-label {
  font-size: 11px;
  font-weight: 600;
  color: #4a4a4a;
  background-color: #dddddd;
  padding-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  margin-left: 10px;
}

.StatusLabel {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  align-self: start;
  min-height: 20px;
  &.green {
    background-color: #3ac358;
    color: white;
  }
  &.red {
    background-color: #fa5659;
    color: white;
  }
  &.voided {
    background-color: #ba1010;
    color: white;
  }
  &.yellow {
    background-color: #f4cf0d;
  }
  &.gray,
  &.grey {
    background-color: #d1d5db;
  }
}

.TicketListWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  margin-left: 4px;
  margin-top: 4px;
  margin-bottom: 8px;
}
