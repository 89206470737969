.Wallet {
  .sort-div {
    float: right;
    margin-bottom: 20px;

    .sort-label {
      display: inline-block;
      margin-right: 10px;
      color: #4a4a4a;
    }

    .sort-dropdown {
      min-width: 120px !important;
      border-radius: 0px;
      &:focus {
        border-color: #4a4a4a9a !important;
      }
      &:active {
        border-color: #4a4a4a9a !important;
      }
      .visible.menu.transition {
        border-color: #4a4a4a9a !important;
      }
    }
  }

  &-transfer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 16px;
    &-field {
      flex-grow: 1;
    }
  }

  .ui.statistic {
    line-height: 30px;
    .value {
      font-weight: 700;
    }
    .label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      color: #4a4a4a;
    }
  }

  th {
    background-color: #efefef !important;
    color: #4a4a4a !important;
  }

  button {
    background-color: #efefef !important;
    color: #4a4a4a !important;
    &.linky {
      padding: 5px !important;
      background-color: transparent !important;
      font-size: 14px !important;

      &.enabled {
        color: #0085ff !important;
      }
      &:hover {
        filter: brightness(1.2);
      }
    }
  }

  h2 {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
  }
}
