.SuggestLabelsModal {
  padding: 40px;
  font-family: Lato;
  font-style: normal;

  color: #5c5c5c;

  &-Trigger {
    position: sticky;
    bottom: 0;
    margin-left: -10px;
    padding-left: 10px;
    background-color: white;

    &-divider {
      margin: 0 -10px 10px -10px !important;
    }

    &-text {
      padding: 0 8px 8px 8px;
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      color: #0085ff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-Header {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.58px;
    margin-bottom: 14px;
  }

  &-Success {
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
    font-family: Lato;
    font-style: normal;
    align-items: center;
    text-align: center;

    &-icon {
      width: 28px;
      height: 28px;
      margin-bottom: 24px;
    }

    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: -0.58px;
      color: #5c5c5c;
      margin-bottom: 8px;
    }

    &-paragraph {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #898a8d;
      margin-bottom: 24px;
    }
  }

  // Variants.
  .CustomPriorityItemField & {
    &-Trigger {
      background-color: transparent;

      &-divider {
        display: none;
      }
    }
  }
}
