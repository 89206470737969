.PaginationLinks {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #0085ff;

  &-First,
  &-Next {
    margin-right: 10px;
  }

  &-Prev {
    margin-right: 20px;
  }

  &-First,
  &-Next,
  &-Prev,
  &-Last {
    &:hover {
      cursor: pointer;
    }

    &.disabled {
      color: #898a8d;
      pointer-events: none;
      &:hover {
        cursor: none;
      }
    }
  }
}
