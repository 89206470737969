.OnboardWelcome {
  font-family: Lato;
  font-style: normal;

  &-body {
    background-color: #bbb6d1;
    padding: 87px 55px;
    text-align: center;
    color: #ffffff;

    &-icon {
      margin: auto;
      margin-bottom: 20px;
    }

    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: -0.58px;
      margin-bottom: 20px;
    }
    &-paragraph {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      padding: 0 100px;
    }
  }

  &-tips {
    color: #4a4a4a;
    background-color: #ffffff;
    padding: 30px 40px;

    &-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
