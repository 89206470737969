.TicketDeliverableModal {
  padding: 40px 32px;
  &-title {
    font-weight: 700;
    color: #4a4a4a;
    font-size: 20px;
    display: flex;
    column-gap: 8px;
    align-items: center;
  }
  &-description {
    margin-top: 16px;
    color: #56488c;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
    &-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  &-dropdown-container {
    display: flex;
    column-gap: 8px;
  }
  &-dropdown-option {
    display: flex;
    align-items: center;
  }
  &-dropdown-warning {
    display: flex;
    align-items: center;
    color: #e87b17;
  }
  &-line {
    margin: 16px 0px;
    width: 100%;
    border: 0.75px solid #c7c7c7;
  }
  &-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #898a8d;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    &-title {
      margin-top: 16px;
      font-weight: 700;
    }
  }
  &-details {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    width: 60%;
  }
  &-history-status {
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin: 8px 0px;
  }
}
