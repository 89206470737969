.NominateExpert,
.StartupInvite {
  font-family: Lato;
  font-style: normal;
  color: #4a4a4a;

  &-breadcrumb-footer {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  &-Form {
    background: #ffffff;
    padding: 24px;

    &-Header {
      display: flex !important;
      justify-content: space-between;

      &-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
      }

      &-currPage {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #56488c;
      }
    }

    &-EntityType {
      &-buttons {
        & > button.ui.button {
          color: #56488c !important;
          -webkit-box-shadow: 0 0 0 1px #56488c inset !important;
          box-shadow: 0 0 0 1px #56488c inset !important;

          &.active {
            background: #e6e4ee !important;
          }

          &:hover {
            filter: none;
          }
        }
      }
    }
  }

  &-RightCol {
    &-header {
      display: flex;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 24px;
      align-items: center;

      & > img {
        width: 46px;
        height: 46px;
        margin-right: 12px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }

    &-paragraph {
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }

  & .ui.divider {
    border-top-color: #8d84b5 !important;
  }
  &-note {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: #56488c;
  }
  &-modal {
    text-align: center !important;
    padding: 64px 80px;
    &-title {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #4a4a4a;
    }
    &-paragraph {
      font-size: 14px;
      line-height: 22px;
    }
    &-button {
      margin-top: 46px !important;
    }
  }
}
