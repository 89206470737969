.ExpertOnboardCard {
  max-width: 348px;
  min-width: 348px;
  height: auto;
  border-radius: 4px;
  border: 1px solid #dddddd;
  overflow: hidden;
  background-color: white;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  // hack for now
  &.Committee-review {
    background-color: transparent;
    border: none;
  }

  &-link {
    color: #0085ff;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  &-label {
    width: 100%;
    padding: 8px 16px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    background-color: #8d84b5;

    &.Awaiting-seconders {
      background-color: #e7aa0c;
    }
    &.Mutual-assessment {
      background-color: #29d9b9;
    }
    &.Committee-review {
      background-color: #0070d2;
    }
  }
  &-header {
    padding: 16px 16px;
    background-color: #e6e4ee;
    display: flex;
    column-gap: 16px;
    min-height: 137px;
    img {
      min-width: 89px !important;
      min-height: 89px !important;
      max-width: 89px !important;
      max-height: 89px !important;
    }
    &-title {
      line-height: 22px;
      color: black;

      &-sub {
        font-size: 12px;
        color: #4a4a4a;
      }
    }
  }
  &-body {
    padding: 16px 16px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: #4a4a4a;
    background-color: white;
    &-list {
      display: flex;
      column-gap: 4px;
    }
    &-description {
      margin-top: 8px;
    }
    .ui.button {
      padding: 0.58571429em 1.5em 0.58571429em !important;
    }
    &-volunter {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      &-missing {
        margin-left: 8px;
        color: #e87b17;
      }
      &-label {
        color: #56488c;
        display: flex;
        column-gap: 8px;
        &-sub {
          color: #8d84b5;
        }
      }
    }
    &-evaluation {
      &-label {
        font-weight: 700;
        color: #bbb6d1;
      }
      &-sub {
        font-weight: 700;
        color: #8d84b5;
      }
      &-seconder-row {
        display: flex;
        flex-wrap: nowrap;
        column-gap: 8px;
        color: #898a8d;
        margin-bottom: 8px;
        img {
          max-width: 24px;
          min-width: 24px;
          max-height: 24px;
          min-height: 24px;
        }
        &-user {
          font-weight: 700;
          flex-shrink: 100000;
        }
        &-await-label {
          color: #e87b17;
        }
        &-evaluated-label {
          color: #2d9031;
        }
      }
    }
  }
}
