.BodyWrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "header  header  header"
    "sidebar breadcrumb breadcrumb"
    "sidebar content content"
    "sidebar  content  content";
}

.BodyWrapper-NewUser {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "header  header  header"
    "breadcrumb breadcrumb breadcrumb"
    "content content content"
    "content  content  content";
}
