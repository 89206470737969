.StartupDealflow {
  &-breadcrumb {
    h2 {
      margin-bottom: 8px;
    }
    a {
      font-weight: 700;
      color: #0085ff !important;
      &:hover {
        filter: brightness(0.9);
      }
    }
    &-links {
      margin-top: 8px;
      display: flex;
      column-gap: 16px;
    }
  }
  &-empty-list {
    margin-top: 16px;
    max-width: 170px;
    min-width: 170px;
    padding: 24px;
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
      min-height: 110px;
      min-width: 110px;
      margin-bottom: 16px;
    }
    &-title {
      color: #4a4a4a;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }
  }
  &-link {
    color: #0085ff !important;
    display: flex;
    column-gap: 8px;
  }
  &-step {
    display: flex;
    column-gap: 16px;
    margin-bottom: 32px;
    &-left {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      &-bar {
        margin-left: 8px;
        margin-right: 8px;
        border-left: 1px solid #56488c;
        height: 100%;
        &.unsuccessful {
          border-left: 1px solid #ba1010;
        }
      }
      &-circle {
        min-width: 16px;
        min-height: 16px;
        max-width: 16px;
        max-height: 16px;
        background-color: #56488c;
        border-radius: 8px;
        &.unsuccessful {
          background-color: #ba1010;
        }
      }
    }
    &-right {
      width: 100%;
      &-wrap {
        display: flex;
        justify-content: space-between;
        &-label {
          color: #8d84b5;
          font-family: Lato;
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0px;
          margin-right: 16px;
          &.secondary {
            color: #56488c;
          }
          &.unsuccessful {
            color: #ba1010;
          }
        }
      }
      &-description {
        color: #56488c;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
      }
      &-list-row {
        margin-top: 16px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 100px;
        overflow: hidden;
        align-items: stretch;
      }
      &-list-infinite {
        margin-top: 16px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 16px;
        overflow: hidden;
        align-items: stretch;
        padding-bottom: 20px;
      }
    }
  }
}
