.BidOutcome {
  width: 290px !important;
  background-color: #f6f6f6 !important;
  &-TopSection {
    background-color: white !important;
    padding-bottom: 16px !important;

    &-header {
      margin-top: 2px !important;
      margin-bottom: 30px !important;
      color: #4a4a4a !important;
      font-size: 20px !important;
      font-weight: 550 !important;
      letter-spacing: -0.58px !important;
    }

    &-label {
      display: inline-block;
      text-align: center;
      min-width: 80px;
      padding-left: 8px;
      padding-right: 8px;
      height: 25px;
      border-radius: 4.35px;
      color: white;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      margin-right: 10px;

      &.green {
        background: #3ac458;
      }
      &.red {
        background: #fa5659;
      }
      &.voided {
        background-color: #ba1010;
        color: white;
      }

      & > i {
        margin: 0 0 0 5px;
        font-size: 0.8em;
      }
    }
    &-note {
      margin-top: 8px;
      font-family: Lato;
      font-size: 14px;
      color: #898a8d;
    }

    & .description {
      color: #898a8d !important;
      font-size: 11px;
      font-weight: 600;
    }
  }

  &-BottomSection {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px;
    color: #4a4a4a;

    &-buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      & > .ui.button {
        height: 26px;
        padding: unset;
      }
    }
    &-request-eval {
      color: #0085ff;
      font-size: 12px;
      font-weight: 600;
      &:hover {
        cursor: pointer;
        filter: brightness(0.85);
      }
    }
    &-requested-eval {
      font-size: 12px;
      font-weight: 600;
      color: #4a4a4a;
    }
  }
}

.Bid-feedback {
  width: 290px !important;
  .header {
    margin-top: 2px !important;
    color: #4a4a4a !important;
    font-size: 20px !important;
    font-weight: 550 !important;
    letter-spacing: -0.58px !important;
    margin-bottom: 5px !important;
  }
  .sender {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    color: #898a8d;
    margin-top: 15px;
    .name {
      font-size: 13px;
    }
    .date {
      font-size: 12px;
    }
  }
  .feedback {
    font-size: 14px;
    font-weight: 400;
    color: #898a8d;
    margin-top: 15px;
    line-height: 21px;
  }
}
