.AddressBookFormDropdown {
  .ui.selection.dropdown .menu {
    min-width: fit-content;
    border-width: 1px !important;
    margin-bottom: 10px;
    border: 1px solid #bfbfbf !important;
  }
  .ui.active.selection.dropdown {
    border-bottom-left-radius: 0.28571429rem !important;
    border-bottom-right-radius: 0.28571429rem !important;
    border: 1px solid #bfbfbf;
  }
  .ui.search.dropdown > .text {
    color: #4a4a4a;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  &-option {
    color: #4a4a4a;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    .icon-label {
      display: flex;
      column-gap: 8px;
      align-items: center;
      img {
        min-height: 24px;
        max-height: 24px;
        max-width: 24px;
        min-width: 24px;
      }
    }
    .eth-address {
      font-size: 12px;
    }
  }
}
