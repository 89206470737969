.TktDashCrumbs {
  &-collum {
    min-width: 150px;
  }
  &-collum-title {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #898a8d;
  }
  &-collum-content {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #4a4a4a;
  }
  &-success-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-link {
    display: inline-flex;
    color: #0085ff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.58px;
    img {
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.85);
    }
  }
}
