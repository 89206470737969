.OnboardHeader {
  &-breadcrumb {
    display: flex;
    align-items: center;
    color: #0085ff;
    font-family: Lato;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 16px;
    > img {
      transform: rotate(180deg) !important;
      margin-right: 8px;
      margin-top: 2px;
    }
    &:hover {
      cursor: pointer;
      filter: brightness(0.85);
    }
  }

  &-image {
    width: 87px;
    height: 87px;
    min-width: 87px;
    min-height: 87px;
    margin-top: 5px;
  }

  &-description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #4a4a4a;
    word-break: break-word;

    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: #5c5c5c;
      margin-bottom: 8px;

      &.hidden {
        color: white;
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        -ms-user-select: none; /* IE 10+ */
        user-select: none;
        margin-bottom: 8px;
      }
    }
    &-small-label {
      display: inline-flex;
      font-family: Lato;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #898a8d;
      word-break: break-word;
    }
    &-warning {
      margin-left: 8px;
      font-family: Lato;
      font-size: 10px;
      font-weight: 400;
      line-height: 22px;
      color: #e87b17;
      word-break: break-word;
    }
  }
  &-referrer-row {
    display: flex;

    & img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      margin-right: 4px;
    }
  }
  &-volunter {
    display: flex;
    column-gap: 16px;
    font-weight: 700;
    &-label {
      color: #56488c;
      &-sub {
        color: #8d84b5;
      }
    }
  }
  &-assigned {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    font-weight: 700;
    &-label {
      display: ruby;
      color: #56488c;
      &-sub {
        color: #8d84b5;
      }
    }
  }
}
