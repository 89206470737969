.ui.container.InputFieldCharCount {
  margin-right: 0 !important;
  margin-top: -1em;
  opacity: 0.5;
  margin-bottom: 10px;

  &.InputFieldCharCount--maxed {
    color: red;
    font-weight: bold;
    opacity: 1;
  }
}
