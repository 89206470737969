.CheckboxFilter {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-label {
    color: #898a8d;
  }

  .MultiSelect {
    min-width: 150px;

    & > .dropdown-container,
    & > .dropdown-container:focus-within {
      outline: none;
      background-color: transparent;
      border: none;
      box-shadow: none;

      .dropdown-heading-value {
        font-weight: bold;
      }
    }

    .dropdown-content {
      margin-right: -1px;
      max-width: 150px;
      right: 0;

      & ul label,
      & ul li {
        background-color: white;
      }
    }
  }
}
