.CustomHorizontalRadioRaterField {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &-rater {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #898a8d;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  &-options {
    display: flex;
    column-gap: 16px;
    &.flat {
      column-gap: 32px;
    }
    input {
      cursor: pointer;
    }
  }
  .field {
    cursor: pointer;
    margin: 0px !important;
    &.flat {
      display: flex !important;
      flex-direction: row-reverse;
      column-gap: 12px;
    }
  }
  label {
    cursor: pointer;
    margin: 0px !important;
    font-family: Lato;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: center;
    color: #898a8d !important;
    &.flat {
      color: #4a4a4a !important;
    }
  }
}
