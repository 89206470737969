$headerHeight: 7em;
$subHeaderHeight: 3.5em;
$summaryHeight: auto;
$extraInfoHeight: 80px;

.PendingReview {
  background-color: #f6f6f6 !important;
  &-bottom-label {
    margin-top: 8px;
    display: flex;
    column-gap: 8px;
    line-height: 16px;
    color: #56488c;
  }
}

.StartupCard {
  height: auto;
  width: 349px;
  background-color: white;
  margin: 8px 32px 24px 0px;
  color: black;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 24px 16px !important;
  line-height: 28px;

  &-ribbon {
    width: 150px;
    transform: translateX(-90%) !important;
    background-color: $topSearchPurple !important;

    &.ui.label {
      color: white;
      border-color: $topSearchPurple_d1 !important;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-title {
      color: black;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 400;
    }

    &-logo {
      & > img {
        width: 60px;
        height: 60px;
      }
    }
  }

  &-timeleft {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    align-items: center;
    > img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    margin-bottom: 8px;
  }

  &-sub-header,
  &-summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
  }

  &-sub-header {
    display: flex;
    justify-content: space-between;
    height: $subHeaderHeight;

    &-right {
      display: flex;
      orientation: row;
    }
  }
  &-information {
    margin-bottom: 16px;
    &-row {
      &:not(:first-child) {
        margin-top: 4px;
      }
      width: 100%;
      line-height: 18px;
    }
  }

  &-summary {
    height: $summaryHeight;
    -webkit-line-clamp: 7;
  }

  &-incentive-vote {
    color: #023373;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &-buttons {
    display: flex;
    align-items: flex-end;
    & button {
      color: white !important;
      background-color: #023373 !important;
      font-size: 15px;
      font-weight: 700;
      padding: 8px 32px 9px 32px;
      &:hover {
        color: white !important;
        background-color: scale-color(#023373, $lightness: -20%) !important;
      }
    }
  }
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
}
