.SignaturePaywall {
  &.ui.active.modal {
    width: 495px;
    height: 345px;
    padding: 49px;
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    text-align: center;
    position: relative;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  & > &-icon {
    width: 44px;
    height: 44px;
  }

  & > h2 {
    font-size: 30px;
    line-height: 36px;
  }

  & > p {
    color: #898a8d;
  }

  & > a {
    margin-top: 10px;
    color: #0085ff;
  }

  & > &-progress {
    &,
    &::before {
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 0;
      margin-bottom: 0 !important;
      overflow: hidden;
    }

    &::before {
      content: "";
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 30%,
        rgba(255, 255, 255, 0) 81%
      );
      background: -o-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 30%,
        rgba(255, 255, 255, 0) 81%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 30%,
        rgba(255, 255, 255, 0) 81%
      );
      -webkit-animation: signature-paywall-loader 2s ease-in infinite;
      -moz-animation: signature-paywall-loader 2s ease-in infinite;
      animation: signature-paywall-loader 2s ease-in infinite;
    }
    background-color: #56488c;
  }
}

// Loader animation
@keyframes signature-paywall-loader {
  0% {
    transform: translate3d(-160%, 0, 0);
  }
  100% {
    transform: translate3d(60%, 0, 0);
  }
}
