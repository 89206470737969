.OnboardForm {
  padding: 20px 24px 20px 16px !important;

  &-titleWrapper {
    display: flex;
    justify-content: space-between;
    &-title {
      display: flex;
      align-items: baseline;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
      color: #000000;

      &-extra {
        margin-left: 18px;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #898a8d;
        letter-spacing: -0.58px;
      }
    }
  }

  &-bttn-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .ui.form {
    .ui.input,
    .ui.input > input {
      min-width: 317px;
      width: 100%;
      max-width: unset;
    }
  }
  .ui.form textarea:not([rows]) {
    height: 8em;
  }
  .CustomCheckboxTree .CustomCheckboxTree-Dropdown-wrapper {
    .ui.input,
    .ui.input > input {
      min-width: 317px !important;
      width: 500px !important;
      max-width: 500px !important;
      max-height: 330px !important;
    }
  }

  .CustomCheckboxTree.active .CustomCheckboxTree-Dropdown-wrapper .CustomCheckboxTree-Dropdown {
    min-width: 317px !important;
    width: 500px !important;
    max-width: 500px !important;
    max-height: 330px !important;
  }

  .CustomCheckboxTree.active .CustomCheckboxTree-Dropdown-wrapper .CustomCheckboxTree-Dropdown {
    position: static;
  }
  .CustomCheckboxTree.active .CustomCheckboxTree-Dropdown {
    transition: max-height 0.2s ease-in;
    max-height: 330px;
  }
  .CustomCheckboxTree:not(.active) .CustomCheckboxTree-Dropdown {
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    background: #ffffff;
    border: 0px solid #c7c7c7;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;
    margin-left: 1.8px;
    width: 495px;
  }
  .CustomCheckboxTree {
    .ui.input > input {
      transition: border-bottom 0.5 linear;
      border-bottom: 0px solid rgba(34, 36, 38, 0.15);
    }
  }
  .CustomCheckboxTree-LabelGroup {
    max-width: 550px;
  }

  .DocumentBadge {
    max-width: 500px;
  }
  .ui.button.SignatureButton {
    width: auto;
  }
}

.WhyJoinForm {
  font-family: Lato;
  font-size: 14px;
  line-height: 22px;
  color: #4a4a4a;

  img {
    margin-right: auto;
    margin-left: auto;
  }
  &-top-container {
    padding: 48px 66px;
    background-color: white;
  }
  &-bottom-container {
    padding: 40px 66px;
    background-color: #f1f0f6;
  }
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 8px;
  }
  &-title-sub-text {
    font-weight: 700;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  &-lists {
    display: flex;
    column-gap: 16px;
    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 50%;
    }
    &-item {
      display: flex;
      column-gap: 8px;
    }
  }
  &-list-dot {
    background-color: #56488c;
    min-height: 8px;
    min-width: 8px;
    max-width: 8px;
    max-height: 8px;
    border-radius: 4px;
    margin-top: 8px;
  }
}

.AwaitingVoteApproval {
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 16px;
  padding: 60px;
  padding-bottom: 200px;
  color: #4a4a4a;
  font-weight: bold;
  font-size: 14px;
  &-title {
    font-size: 20px;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    width: 58px;
    height: 58px;
  }
}
