.PortfolioCard {
  background-color: white;
  height: $startupCardHeight;
  width: $startupCardWidth;
  margin: 8px 32px 24px 0px;
  border: 1;
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 1px solid rgba(34, 36, 38, 0.15);

  &:hover {
    cursor: pointer;
  }

  & a {
    height: 100%;
    display: flex;
    flex-direction: column;
    &:hover {
      text-decoration: none;
    }
  }

  &-top,
  &-bottom {
    height: 100%;
  }

  &-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & > img {
      max-width: 140px;
      max-height: 140px;
      object-fit: contain;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 150%;
      border-bottom: 0.5px solid #c7c7c7;
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &-name {
      font-size: 2em;
      line-height: 2em;
      color: black;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &-oneLiner {
      margin-top: 1em;
      height: 5em;
      max-height: 5em;
      font-size: 1.1em;
      color: black;
      overflow-x: auto;
      overflow-y: auto;
    }
  }
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
}
