.ui.button.SignatureButton {
  padding-left: 9px;
  padding-right: 9px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background: #0084ff;
  color: white;
  width: 100px;

  & > .icon.pencil:first-child {
    margin-right: 12px;
  }

  &.basic {
    border: 2px solid #0085ff;
    border-radius: 5px;
    color: #0085ff !important;

    &:not(:hover) {
      box-shadow: unset;
    }
  }
  &:hover {
    background: #1e90fa;
  }
}
