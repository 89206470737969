.BallotsTabSelector {
  width: 100%;

  &-no-votes {
    height: 385px;
    min-height: 385px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      margin: 53px 0 12px 0;
    }

    &-description {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #4a4a4a;
    }
  }

  &-disclaimer {
    display: flex;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #56488c;

    & > img {
      width: 19px;
      height: 19px;
      margin-right: 10px;
      margin-bottom: 42px;
    }
  }

  &-pageTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #4a4a4a;

    &.small-margin {
      margin: 0 0 8px 0;
    }
  }

  &-pageSubtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;

    &.small-margin {
      margin: 0 0 18px 0;
    }
  }

  &-header {
    display: flex;
    margin-top: 8px;
    column-gap: 16px;
    &-column {
      position: relative;
      justify-content: center;
      border: 1px solid #e6e4ee;
      text-align: center;
      min-width: 100px;
      min-height: 64px;
      padding: 8px 16px;
      .is-loading & {
        opacity: 0.5;
        cursor: default !important;

        &.selected {
          opacity: 1;
        }
      }

      &.selected {
        background-color: #56488c;
        .BallotsTabSelector-vote-yes,
        .BallotsTabSelector-vote-no,
        .BallotsTabSelector-vote-neutral,
        .BallotsTabSelector-vote-for-discussion {
          color: white;
          font-weight: bold;
        }
        .BallotsTabSelector-vote-counter {
          color: white;
        }
      }

      .BallotsTabSelector-vote-counter {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        justify-content: center;
        color: #4a4a4a;
        margin-bottom: 4px;
      }

      .BallotsTabSelector-vote-yes,
      .BallotsTabSelector-vote-no,
      .BallotsTabSelector-vote-neutral {
        & i {
          margin-left: 6px;
        }
      }

      .BallotsTabSelector-vote-yes {
        color: #2d9031;
      }

      .BallotsTabSelector-vote-no {
        color: #ba1010;
      }

      .BallotsTabSelector-vote-neutral {
        color: #bfbfbf;
      }
      .BallotsTabSelector-vote-for-discussion {
        color: #56488c;
      }

      &:hover {
        cursor: pointer;
      }

      &.selected::after {
        content: "";
        background-color: #f1f0f6;
        visibility: hidden;
        position: absolute;
        content: "";
        top: 100%;
        left: calc(50% - 8px);
        transform: translateX(-20%) translateY(42%) rotate(225deg);
        width: 20px;
        height: 20px;
        border: none;
        border-bottom: 2px solid #8d84b5;
        border-right: 2px solid #8d84b5;
        z-index: 2;
        transition: background 0.1s ease;
        visibility: visible;
      }
    }
  }

  &-body {
    background: #f1f0f6;
    border-top: 2px solid #8d84b5;
    margin-top: 17px !important;
    padding-bottom: 35px !important;
    font-family: Lato;
    font-style: normal;

    font-family: Lato;
    font-style: normal;
    color: #56488c;

    &-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
    }

    &-grid {
      width: 100%;
      margin: 0 !important;
    }

    &-first-row {
      font-size: 10px;
    }
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
}
