.Ticket-labels {
  color: white;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  &.red {
    background-color: #fa5659;
  }
  &.blue {
    background-color: #2ca4bf;
  }
}
