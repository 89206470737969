.Rater {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 20px;
  margin-top: -10px;
  .Rate {
    cursor: pointer;
    width: 30px;
    font-size: 17px;
    padding: 3px 8px;
    color: lightgrey;
    font-weight: bold;
    text-align: center;
    border: 2px solid;
    border-radius: 2px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    &.selected {
      color: rgb(44, 164, 191);
    }
  }
  .Value {
    font-size: 12px;
    text-align: center;
    color: gray;
  }
}
.Rater:last-child {
  margin-right: auto;
  margin-bottom: 0px;
}

.Evaluation {
  width: 100%;
  .header {
    display: flex;
    justify-content: flex-start;
    margin: 15px 25px;
    .name {
      color: #898a8d;
      font-size: 13px;
      font-weight: 600;
      margin-right: 10px;
    }
    .date {
      color: #898a8d;
      font-size: 11px;
      font-weight: 600;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 600;
    min-height: 45px;
    .label {
      margin-left: 25px;
      margin-right: 10px;
    }
    .score {
      min-height: 25px;
      min-width: 25px;
      max-height: 25px;
      max-width: 25px;
      color: #56488c;
      font-size: 13px;
      font-weight: 700;
      background-color: rgba(86, 72, 140, 0.15);
      border-radius: 5px;
      margin-right: 25px;
      text-align: center;
      vertical-align: middle;
      line-height: 25px;
    }
  }
  .row:nth-child(odd) {
    background-color: rgba(152, 152, 152, 0.1);
  }
  .additional-comments {
    margin-right: 65px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 600;
    .comment {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 400;
      color: #898a8d;
    }
  }
}
.popup-content {
  color: #56488c;
  font-size: 13px;
  font-weight: 700;
  .wrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    .score {
      min-height: 25px;
      min-width: 25px;
      max-height: 25px;
      max-width: 25px;
      color: #56488c;
      font-size: 13px;
      font-weight: 700;
      background-color: rgba(86, 72, 140, 0.15);
      border-radius: 5px;
      margin-right: 10px;
      text-align: center;
      vertical-align: middle;
      line-height: 25px;
    }
  }
  .wrapper:last-child {
    margin-bottom: 0px;
  }
}

.EvaluationForm {
  font-weight: 600;
  font-size: 14px;
  color: #4a4a4a;

  .grouped {
    margin-bottom: 25px;
    & .ui.red.basic.pointing.label {
      display: table;
    }
  }
  .field {
    &:not(:first-child) {
      display: inline-block !important;
      margin-right: 30px !important;
      label {
        color: #4a4a4a;
        font-size: 12px;
      }
    }
  }
}
