.KYCForm {
  width: 100%;
  &-proof-of-address {
    width: 50%;
    .modal-input {
      max-width: unset;
    }
    .clndr-calendars-segment.clndr-top {
      min-height: 305px;
    }
    &-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: #4a4a4a;
      margin-bottom: 24px;
    }
  }
  &-onfido {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
  }

  .onfido-sdk-ui-Modal-inner {
    margin: 0px;
  }
  &-validating-onfido {
    text-align: center;
    background-color: white;
    border: 1px solid #9f9f9f;
    padding: 26px;
    border-radius: 8px;
    font-size: 24px;
    line-height: 24px;
    overflow: hidden;
    width: calc(min(80%, 410px));
    transition: all 0.5s;
    &.collapse {
      font-size: 0;
      width: 0;
      padding: 0;
      border: 0px;
    }
  }
  &-invalid-result {
    text-align: center;
    background-color: white;
    border: 1px solid #9f9f9f;
    padding: 40px;
    border-radius: 8px;
    font-size: 24px;
    line-height: 24px;
    margin-top: 1em;
    width: calc(min(90%, 570px));
  }
}

@media only screen and (max-width: 1200px) {
  .KYCForm-proof-of-address {
    width: 100% !important;
  }
}
