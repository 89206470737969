.AdminExpertDetailsBreadcrumb {
  &-Status {
    display: flex;
    grid-gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.58px;
    color: #11100f;
    align-items: center;

    &-label {
      font-size: 12px;
      line-height: 22px;
      color: #898a8d;
    }
  }

  &-title {
    font-size: 12px;
    line-height: 22px;
    color: #898a8d;
  }

  &-details {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
  }
}
