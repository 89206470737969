.wrapper {
  margin: 10;
}

.card-loader {
  width: 280px;
  margin-right: 30px;
  background-color: rgb(211, 211, 211);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08), 0 -1px 3px 0 rgba(0, 0, 0, 0.06);
  padding: 8px;
  margin-top: 10px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
  height: 295px;
  display: inline-block;
  overflow: hidden;
  padding: 15px;

  &-header {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
    display: inline-block;
  }

  &-title {
    content: "";
    margin-left: 15px;
    width: 200px;
    height: 40px;
    background-color: white;
    display: inline-block;
  }

  &-body {
    content: "";
    margin-top: 20px;
    width: 100%;
    height: 60px;
    background-color: white;
  }

  &-bottom {
    content: "";
    margin-top: 20px;
    height: 35px;
    width: 90px;
    background-color: white;
    float: right;
  }

  &:after {
    content: "";
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}

// Loader animation
@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
