.SessionDropdown {
  padding: 0px;
  &-option {
    width: 100% !important;
    color: #4a4a4a !important;
    font-family: Lato;
    font-size: 14px;
    &:hover {
      color: black !important;
    }
    &.logout {
      color: #0085ff !important;
      margin-top: 8px;
    }
    &:hover {
      color: black !important;
    }
  }
}

.IdentityBadge {
  height: 13px;
  width: 13px;
  min-width: 13px;
  min-height: 13px;
  filter: brightness(1.65);
  border-radius: 10px;
  top: 22.5px;
  margin-left: 9px;

  &-content {
    top: 3px !important;
    left: 14px !important;
  }
}
