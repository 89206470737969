.SentInviteBreadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-right: 1.5em;
  row-gap: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;

  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #5c5c5c;
  }
  &-link {
    color: #0085ff;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
  &-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &-subtitle {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #898a8d;
  }
  button {
    margin: 0px !important;
  }
  &-separator {
    width: 100%;
    border: 1px solid #dddddd;
  }
  .ui.container {
    width: auto !important;
  }
}
