.AskAQuestion {
  font-family: Lato;
  font-style: normal;
  color: #5c5c5c;
  line-height: 22px;
  min-width: 220px;

  &-header {
    font-weight: 600;
    font-size: 20px;

    letter-spacing: -0.58px;
  }

  &-description {
    font-weight: normal;
  }

  &-label {
    font-weight: 600;
  }

  &-button.ui.button {
    color: white;
    background: #0085ff;
    border: 1px solid #0085ff;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
