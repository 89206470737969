.CreateTicketOverlay {
  margin: 0;
  padding: 50px;
  &-closeBttn {
    display: flex !important;
    flex-direction: row-reverse !important;

    & img:hover {
      cursor: pointer;
    }
  }

  &-header {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 22px;
    color: #5c5c5c;
    margin-bottom: 60px;
  }

  &-submitBttn {
    background: #0085ff !important;
    color: white !important;
  }
}
