.Footer {
  grid-area: footer;
  background-color: rgb(27, 28, 29);
  text-align: center !important;
  margin: 0px -2.5em;
  &-title {
    color: #fff;
    font-size: 1.07142857rem;
    font-weight: 700;
  }
  &-feedback {
    display: flex !important;
    justify-content: center !important;
  }
}
