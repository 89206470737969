%line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ClampWrapper {
  overflow: hidden;
  word-break: break-all;
}

.LineClamp {
  &-1 {
    @extend %line-clamp;
    -webkit-line-clamp: 1;
  }
  &-2 {
    @extend %line-clamp;
    -webkit-line-clamp: 2;
  }
  &-3 {
    @extend %line-clamp;
    -webkit-line-clamp: 3;
  }
  &-4 {
    @extend %line-clamp;
    -webkit-line-clamp: 4;
  }
  &-5 {
    @extend %line-clamp;
    -webkit-line-clamp: 5;
  }
  &-6 {
    @extend %line-clamp;
    -webkit-line-clamp: 6;
  }
  &-7 {
    @extend %line-clamp;
    -webkit-line-clamp: 7;
  }
}
