.TicketSummary {
  min-width: 290px;
  padding: 10px 10px !important;
  margin-top: 1rem !important;

  &:first-child {
    margin-top: 0px !important;
  }

  &-title {
    font-weight: 600;
    font-size: 22px !important;
    letter-spacing: -0.58px;
    color: #5c5c5c !important;
  }
  &-user {
    font-size: 15px;
    color: gray;
  }
  &-date {
    margin-top: 3px;
    font-size: 13px;
    color: gray;
  }
  &-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #898a8d !important;
    margin-bottom: 10px;
  }
  .ticket-form {
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 1em;
    .textArea-input {
      min-height: 70px !important;
      resize: none;
      font-size: 13px;
    }
  }
  .warning {
    background-color: rgba(201, 76, 76, 0.3);
    padding: 10px;
    border-radius: 7px;
    color: black;
    margin-bottom: 10px;
  }
  &-descriptions {
    display: flex;
    justify-content: space-between;
    color: #898a8d !important;
    &:not(:first-child) {
      margin-top: 8px;
    }
    .right-bold {
      font-weight: bold;
      color: #4a4a4a;
      text-align: end;
    }
  }
  &-ticket-divider {
    border-top: 1px solid #c4c4c4 !important;
  }
  &-cvds-price {
    background-color: #f5f5f5;
    margin: 0px -10px -10px -10px !important;
    padding: 18px 24px;
  }
}

.sort-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .sort-label {
    display: inline-block;
    margin-right: 10px;
  }

  .sort-dropdown {
    min-width: 120px !important;
    border-radius: 0px;
  }
}
