.Load-more {
  width: 100%;
  background: linear-gradient(180deg, #ffffff 14.08%, rgba(255, 255, 255, 0) 82.79%);
  transform: rotate(-180deg);
  margin-top: -90px;
  height: 90px;
  .bttn {
    text-align: center;
    transform: rotate(-180deg);
    color: #0085ff;
    font-size: 13px;
    font-weight: 600;
    line-height: 10px;
  }
  .bttn:hover {
    filter: brightness(0.7);
    cursor: pointer;
  }
}
