.CloseIcon {
  position: absolute;
  top: 5px;
  right: 0px;
  color: #696969;
  cursor: pointer;
  :hover {
    filter: brightness(0.5);
  }
}

.PopupForm {
  min-width: 300px !important;
  max-width: 380px !important;
  .Detached {
    margin-top: 5px !important;
  }
}
