.SignInDropdown {
  white-space: pre-line;
  background: white;
  color: black;

  &-column {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;

    & a {
      align-self: center;
      width: 10em;
    }
  }
}
