$chevronSize: 30px;

.LeftMenu {
  grid-area: sidebar;
  background-color: #453a70;
  width: $leftMenuWidth;
  min-width: $leftMenuWidth;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  overflow-y: hidden;
  box-shadow: $defaultBoxShadow;
  overflow: visible;

  .BodyWrapper--collapsed & {
    width: $leftMenuCollapsedWidth;
    min-width: 70px;
  }

  .AppSection {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #68608f;

    &-collapseIcon {
      height: 24px;
      width: 24px;
      &:hover {
        cursor: pointer;
      }
    }
    &-appIcon {
      height: 53px;
      width: 141px;
    }

    .BodyWrapper--collapsed & {
      justify-content: center;
      & > .AppSection-appIcon {
        display: none;
      }

      & > .AppSection-collapseIcon {
        transform: rotate(180deg);
      }
    }
  }

  &-InviteSection {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 20px;
    margin-bottom: 16px;

    .BodyWrapper--collapsed & {
      padding: 0;
    }

    &-button.ui.button {
      width: 100%;
      color: white !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px white inset;
      -webkit-box-shadow: 0 0 0 1px white inset;

      .BodyWrapper--collapsed & {
        display: none;
      }
    }

    &-icon {
      width: 40px;
      height: 40px;

      &:hover {
        cursor: pointer;
      }

      .BodyWrapper--expanded & {
        display: none !important;
      }
    }
  }

  &-FooterExpanded,
  &-FooterCollapsed {
    width: 100%;
    background-color: #383154;
    margin-top: auto;

    &-Info:hover {
      cursor: pointer;
    }

    &-Status {
      display: flex;
      align-items: center;

      &.green {
        color: #3ac358;
      }
      &.orange {
        color: #e87b17;
      }
      &.red {
        color: #fa5659;
      }

      &-icon {
        position: relative;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 6px;

        .green > & {
          background-color: #3ac358;
        }
        .orange > & {
          background-color: #e87b17;
        }
        .red > & {
          background-color: #fa5659;
        }
      }
    }
  }

  &-FooterExpanded {
    padding: 16px;

    &-Info {
      display: flex;
      column-gap: 11px;
      align-items: center;

      &-avatar {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 50%;
      }

      &-rightCol {
        display: flex;
        flex-direction: column;
        color: white;

        &-name {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;

          width: 137px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-role {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          & > img {
            width: 18px;
            height: 18px;
            margin-right: 7px;
          }
        }
      }
    }

    & > .ui.divider {
      border: none;
      border-top: 1px solid #8d84b5;
    }

    &-Wallet {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #bbb6d1;

      &-copyIcon {
        margin-left: 4px;
        display: inline !important;
        height: 16px;
        width: 18px;

        &:hover {
          cursor: pointer;
        }
      }

      &-popup {
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: #2d9131;
      }
    }

    .BodyWrapper--collapsed & {
      display: none;
    }
  }

  &-FooterCollapsed {
    display: flex;
    justify-content: center;
    padding: 8px;

    &-Avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    &-Status-icon {
      position: absolute;
      right: 14px;
      bottom: 8px;
      margin-right: 0;
    }

    .BodyWrapper--expanded & {
      display: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  .LeftMenu {
    width: 35%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .LeftMenu {
    width: 95%;
  }
}
