.ExpertDetailsProfileTab {
  &-left-col {
    line-height: 22px;
    &-title {
      color: #5c5c5c;
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 16px;
    }
    &-note {
      color: #898a8d;
    }
    a {
      color: #0085ff !important;
      &:hover {
        filter: brightness(0.9);
      }
    }
    &-question {
      margin-bottom: 16px;
    }
  }

  &-right-col {
    width: 90%;
    margin-left: auto;
    &-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 16px;
    }
    p {
      line-height: 22px;
    }
  }
}
