.ui.form .grouped.fields.PreselectionRadioField {
  display: flex;

  & > :not(:first-child) {
    margin-left: 48px;
  }

  & > .field {
    position: relative;
  }

  & > .field::after {
    content: "";
    background-color: #f1f0f6;
    visibility: hidden;
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    transform: translateX(-20%) translateY(42%) rotate(225deg);
    width: 20px;
    height: 20px;
    border: none;
    border-bottom: 2px solid #8d84b5;
    border-right: 2px solid #8d84b5;
    z-index: 2;
    transition: background 0.1s ease;
  }

  & > .field.selected::after {
    visibility: visible;
  }

  & .ui.radio.checkbox label:hover {
    cursor: pointer;
  }
}
