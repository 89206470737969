.DeliverableCard {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  border: 1px solid #efefef;
  border-radius: 5px;
  min-width: 258px;
  width: 258px;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
  &-link {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #0085ff;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  &-top-section {
    width: 100%;
    height: calc(100% - 38px);
    background-color: white;
    padding: 8px 12px;
    &-title {
      color: #4a4a4a;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 8px;
    }
    &-description {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: #4a4a4a;
      font-family: Lato;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  &-bottom-section {
    display: flex;
    width: 100%;
    max-height: 38px;
    justify-content: space-between;
    background-color: #f1f0f6;
    padding: 8px 12px;
    color: #898a8d;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    &.warning {
      color: #de0e01;
    }
  }
}

.DeliverableCardsWrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 16px;
}
