.Pre-Completed {
  font-family: Lato;
  font-size: 14px;
  line-height: 22px;
  color: #4a4a4a;

  img {
    margin-right: auto;
    margin-left: auto;
  }
  &-top-container {
    padding: 48px 40px;
    background-color: white;
  }
  &-bottom-container {
    padding: 40px;
    background-color: #f1f0f6;
  }
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 8px;
  }
  &-title-sub-text {
    font-weight: 700;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  &-text-lists {
    display: flex;
    column-gap: 80px;
    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 50%;
    }
    &-item {
      display: flex;
      column-gap: 8px;
    }
  }
  &-members-list {
    display: flex;
    column-gap: 16px;
    row-gap: 32px;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      width: 32%;
      display: flex;
      text-align: center;
      color: #898a8d;
      flex-direction: column;
      row-gap: 4px;
      img {
        width: 111px;
        height: 111px;
        margin-left: auto;
        margin-right: auto;
      }
      &-title {
        color: #5c5c5c;
        font-weight: 700;
      }
    }
  }
}

.PreselectionChampion,
.PreselectionSubmittedBy {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  color: #898a8d;
}

.PreselectionSubmittedBy {
  margin-top: 16px;
}

.PreselectionDetails {
  padding-right: 220px !important;
  margin: 0px !important;
  &-title {
    color: #5c5c5c;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }
  &-links-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
    align-items: center;
  }
  &-links-wrappers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
  }
  .column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ui.divider {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.PendingReviewWrapper {
  display: flex;
  background-color: #56488c;
  column-gap: 4px;
  color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 4px;
  margin-bottom: -20px;
}

@media only screen and (max-width: 991px) {
  .PreselectionDetails {
    padding-right: 0px !important;
  }
}
