.InviteDetails {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: #898a8d !important;
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    color: #4a4a4a;
    margin-bottom: 24px;
  }
  &-sub-title {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    color: #4a4a4a;
    margin-bottom: 8px;
    &-light {
      color: black;
      font-weight: 400;
    }
  }
  &-separator {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    border: 1px solid #8d84b5;
  }
}
