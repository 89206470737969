.AdminUserProfile {
  font-family: Lato;
  font-style: normal;

  &-RolesMenu {
    display: flex;
    overflow-x: auto;

    &-role {
      display: inline-flex;
      max-width: 100px;
      min-width: 100px;
      cursor: pointer;
      color: #56488c;

      &:not(.active) {
        filter: grayscale(1);
      }

      &:not(:first-child) {
        margin-left: 40px;
      }

      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.05) !important;
        border-radius: 5px;
      }

      &-img {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-right: 8px;
      }

      &-text {
        font-weight: bold;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -0.58px;
        text-transform: uppercase;
        color: #56488c;
        text-transform: uppercase;
      }
    }
  }

  &-roleName {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #4a4a4a;

    & a {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &-roleTypeAndKind {
    margin-top: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #4a4a4a;
  }

  &-roleHeadline {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
  }

  &-sectionTitle {
    margin-top: 25px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #898a8d;
  }

  &-divider {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}
