.StepperVertical {
  .Bar {
    background-color: lightgrey;
    margin-left: 10px;
    width: 4px;
    height: 70px;
    &.first {
      height: 45px;
      background-color: #3ac358;
    }
    &.green {
      background-color: #3ac358;
    }
    &.gray,
    &.grey {
      background-color: #e7e7e7;
    }
    &.gray.disable,
    &.grey.disable {
      height: 78px;
      background-color: #e7e7e7;
    }
    &.last {
      height: 45px;
      background-color: #e7e7e7;
    }
  }
  .Stepper-circle {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: white;
    &-first-step {
      margin-left: 3px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: #3ac358;
    }
    &.green {
      border: 3px solid #3ac358;
      color: #3ac358;
    }
    &.grey,
    &.gray {
      margin-left: 3px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: #e7e7e7;
    }
    &.filled {
      color: white;
      background-color: #3ac358;
      background-image: url(../../assets/vertical-stepbar-check.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .Stepper-step {
    margin-left: 5px;
    &.title {
      width: 100%;
      margin-top: 3px;
      font-size: 14px;
      font-weight: 600;
      color: #898a8d;
    }
    &.title.disable {
      width: 100%;
      font-size: 14px;
      margin-top: 0px;
      font-weight: 600;
      color: #898a8d;
    }
    &.label {
      font-size: 12px;
      line-height: 20.8px;
      font-weight: 600;
      color: #9b9b9b;
    }
    &.status {
      font-family: Lato;
      font-size: 14px;
      font-weight: 600;
    }
    &.timestamp {
      font-family: Lato;
      font-size: 10px;
      font-weight: 600;
      color: #898a8d;
    }
  }
  .ui.selection.dropdown {
    min-width: 150px;
    margin-top: 5px;
    > .circle.small.icon {
      margin-left: 5px;
    }
  }
}

.Status-circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;
  &.green {
    background-color: #3ac458;
  }
  &.yellow {
    background-color: #f4cf0d;
  }
  &.red {
    background-color: #eb2626;
  }
}

.StatusTextWrapper {
  display: flex;
  align-items: center;
}

.StatusWrapper {
  display: flex;
  flex-direction: column;
}

.LabelWrapper {
  display: flex;
  justify-content: left;
}

.PopupStatusHistory {
  &.title {
    font-size: 14px;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 10px;
  }
  .ui.divider {
    margin-left: -15px;
    margin-right: -15px;
  }
}
