.MenuItems-Wrapper {
  width: 100%;
  overflow-y: auto;
}

ul.MenuItems {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.MenuItem-label {
  width: 100%;
  display: flex;
  padding: 15px 15px 15px 20px;
  color: #8d84b5;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
}

.MenuItem-disabled {
  display: flex;
  overflow: hidden;
  font-size: 1em;
  color: lightgrey;
  background-color: #463d6a;
  padding: 15px 15px 15px 20px;
  white-space: nowrap;
  width: 100%;
  &-icon-div {
    align-self: center;
  }
  img {
    align-self: center;
    filter: brightness(0.7);
  }
  &-title {
    margin-left: 10px;
  }
}

ul.MenuItems > li.MenuItem {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  position: relative;
  font-size: 1em;
  width: 100%;
  transition: all 0.3s;
}

li.MenuItem > .MenuItem-link,
.SubMenuItem li a {
  position: relative;
  width: 100%;
  display: flex;
  padding: 15px 15px 15px 20px;
  cursor: pointer;
  color: white;
  column-gap: 16px;
}

li.MenuItem--active {
  background-color: #383154;
  border-right: 4px solid white;
}

li.MenuItem .MenuItem-link-icon {
  align-self: center;
  > img {
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
  }
}

li.MenuItem .MenuItem-link-arrow {
  margin-left: auto;
  font-size: 10px;
}

.BodyWrapper--collapsed .MenuItem-link-arrow,
.BodyWrapper--transitioning .MenuItem-link-arrow {
  display: none;
}

.BodyWrapper--collapsed .MenuItem-disabled-title {
  display: none;
}

.BodyWrapper--collapsed .MenuItem-link-title {
  display: none;
}

.BodyWrapper--collapsed ul.MenuItems > li.MenuItem {
  height: 44px;
  flex-direction: row;
  overflow: visible;
}

.BodyWrapper--collapsed li.MenuItem .MenuItem-link {
  padding: 0;
  justify-content: center;
}

.BodyWrapper--collapsed .MenuItem-disabled {
  padding: 15px 15px 15px 15px;
  height: 44px;
  justify-content: center;
}
.BodyWrapper--collapsed li.MenuItem:hover .MenuItem-link {
  font-size: 1.1em;
}

.BodyWrapper--collapsed li.MenuItem .MenuItem-link-icon {
  margin: 0;
}

.SubMenuItem {
  overflow: visible;
}

.BodyWrapper--collapsed .SubMenuItem {
  display: none;
  background-color: $topSearchPurple_d1;
}

.BodyWrapper--collapsed .MenuItem--active .SubMenuItem {
  display: none;
}

.SubMenuItem ul {
  padding-left: 10px;
  list-style: none;
  width: 135px;
}

.SubMenuItem li a {
  font-size: 1em;
  display: inline-block;
  padding: 5px 25px 5px 0;
}

.BodyWrapper--collapsed li.MenuItem:hover .SubMenuItem {
  display: block;
  min-width: 160px;
  position: absolute;
  top: 0;
  left: 70px;
  padding-left: 0;
  box-shadow: $defaultBoxShadow;
}

// Reduce the left position by the border size of active MenuItems (4px).
.BodyWrapper--collapsed li.MenuItem.MenuItem--active:hover .SubMenuItem {
  left: 66px;
}

.MenuItem--active .MenuItem-link-title,
.MenuItem > .MenuItem-link:hover,
.SubMenuItem-title--active,
.SubMenuItem ul > li:hover {
  font-weight: bold;
}
