.NewItensNotificationCircle {
  display: inline-block;
  width: 17px;
  line-height: 17px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #eb2626;
  margin-left: 5px;
}
