.NetworkCard,
.NetworkModal {
  &-header {
    display: flex;
    justify-content: space-between;
    &-labels {
      display: flex;
      align-items: flex-start;
      &-star {
        > img {
          cursor: pointer;
        }
      }
      &-investlabel {
        border: 1px solid #3ac358;
        color: #3ac358;
        padding: 1px 8px;
        font-size: 14px;
        margin-right: 16px;
      }
    }
    > img {
      max-height: 55px;
      max-width: 55px;
      margin: 0px !important;
    }
  }
  &-name {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5799999833106995px;
    color: #5c5c5c;
  }
  &-description {
    font-family: Lato;
    font-size: 14px;
    color: #898a8d;
  }
  &-links {
    display: flex;
    margin-top: 8px;
    font-family: Lato;
    font-size: 14px;
    &-dot {
      background-color: #0085ff;
      border: 2px solid #0085ff;
      height: 4px;
      width: 4px;
      border-radius: 2px;
      align-self: center;
      margin-left: 2px;
      margin-right: 2px;
    }
    &-link {
      margin-right: 4px;
      color: #0085ff;
      &:not(:first-child) {
        margin-left: 4px;
      }
      &:hover {
        cursor: pointer;
        text-decoration: none;
        color: #0085ff;
        font-weight: 700;
        filter: brightness(0.9);
      }
    }
  }
}

.NetworkModal {
  &-tags-section {
    background-color: #f1f0f6;
    padding: 1.5rem;
    &-title {
      width: 100%;
      color: #898a8d;
      font-family: Lato;
      font-size: 14px;
      margin-bottom: 8px;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
    }
    &-tag {
      color: #4a4a4a;
      margin-bottom: 8px;
      margin-right: 8px;
      background-color: white;
      font-size: 14px;
      padding: 8px;
    }
  }
}

.NetworkCard {
  max-width: 350px;
  width: 350px;
  min-width: 250px;
  margin-bottom: 16px;
  margin-right: 16px;
  border: 1px solid #bbb6d1;
  border-radius: 5px;
  padding: 16px;
  background-color: white;

  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
}

.CardWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
