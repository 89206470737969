.StartupDealflowCard {
  max-width: 348px;
  min-width: 348px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  overflow: hidden;
  background-color: white;

  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  &-label {
    width: 100%;
    padding: 8px 16px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    background-color: #8d84b5;
    &.Being-reviewed {
      background-color: #bbb6d1;
    }
    &.Pre-selection {
      background-color: #e7aa0c;
    }
    &.General-assessment {
      background-color: #29d9b9;
    }
    &.Unsuccessful {
      background-color: #fa5659;
    }
  }
  &-header {
    padding: 16px 16px;
    background-color: #e6e4ee;
    display: flex;
    column-gap: 16px;
    min-height: 137px;
    align-items: center;
    img {
      min-width: 89px !important;
      min-height: 89px !important;
      max-width: 89px !important;
      max-height: 89px !important;
    }
    &-title {
      line-height: 22px;
      color: black;
      font-weight: 700;

      &-sub {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
      }
    }
  }
  &-body {
    padding: 16px 16px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: #4a4a4a;
    background-color: white;
    min-height: 190px;

    &-list {
      display: flex;
      column-gap: 4px;
    }
    &-description {
      margin-top: 8px;
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .ui.button {
        padding: 0.58571429em 1.5em 0.58571429em !important;
        margin: 0px !important;
      }
      &-voted {
        display: flex;
        column-gap: 16px;
        color: #56488c;
      }
      &-last-day {
        color: #e87b17;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
}
