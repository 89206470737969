.PreselectionRepliesModal {
  font-family: Lato;
  font-style: normal;
  color: #4a4a4a;

  &-Header {
    padding: 16px 32px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }

  &-Divider {
    margin: 0 !important;
    border: 1px solid #4a4a4a !important;
  }

  &-Content {
    padding: 16px 32px 0 32px;
    max-height: 400px;
    overflow-y: auto;

    &-comment {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.58px;
      color: #8d84b5;
      margin-bottom: 24px;

      &-title {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &-Reply {
      display: flex;
      margin-bottom: 14px;

      &-avatar {
        max-width: 32px;
        min-width: 32px;
        max-height: 32px;
        min-height: 32px;
        margin-right: 20px;
      }

      &-rightCol {
        display: flex;
        flex-direction: column;

        & > div {
          display: flex;
          align-items: baseline;
        }

        &-name {
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          margin-right: 8px;
        }

        &-date {
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
        }

        &-text {
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }

  &-Extra {
    background: #f7f3f3;
    padding: 26px 32px 32px 32px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    & textarea {
      background: #f7f3f3 !important;
    }
  }
}
