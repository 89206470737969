.Breadcrumb {
  grid-area: breadcrumb;
  position: relative;
  background-color: white;
  color: $brandBlack;
  height: auto;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.34), 0 0 6px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  z-index: 200;

  & h2 {
    color: #5c5c5c;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #5c5c5c;
  }

  & p {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    color: #898a8d;
  }

  &-header,
  &-footer,
  &-body {
    margin-left: 1.5em;
  }

  &-body {
    font-weight: 100;
    margin-bottom: 0.1em;
    color: $brandBlack_l1;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 570px) {
    .Breadcrumb-body {
      margin-left: 0px;
    }
  }

  & .ui.breadcrumb {
    margin-left: 25px;

    & a {
      color: $brandBlue;
    }
  }

  &-footer {
    margin-top: 20px;
  }

  .Breadcrumb-children-wrapper {
    display: flex;
    justify-content: space-between;

    .Panes {
      display: flex;
      justify-content: flex-start;
      width: 70%;
      .panel {
        align-self: flex-end;
        padding-bottom: 8px;
        padding-right: 16px;

        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -0.58px;
        text-transform: uppercase;
        color: #898a8d;

        &.active {
          color: #56488c;
          border-bottom: 2px solid #56488c;
          font-weight: 600;
        }
      }
      .panel:hover {
        cursor: pointer;
        color: #56488c;
      }
    }
    .Actions {
      display: flex;
      justify-content: flex-start;
      margin-right: 2.5em;
      margin-bottom: 14px;
      margin-top: -20px;
    }
  }
}
