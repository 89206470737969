.AdminUserCard {
  &-UserInfoCol {
    & > a {
      display: flex !important;
      width: 100%;
    }

    :hover {
      cursor: pointer;
    }

    &-Avatar {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    &-Details {
      display: flex;
      flex-direction: column;

      &-name {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #4a4a4a;
      }

      &-roleCount {
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #898a8d;
      }
    }
  }

  &-EmailCol {
    display: flex !important;
    flex-direction: row-reverse;
    padding-right: 0 !important;
    font-size: 12px;

    &.confirmed {
      color: #3ac358;

      & i {
        margin-left: 10px;
      }
    }

    &.unconfirmed {
      color: #e87b17;
    }
  }

  &-DropdownItem {
    // Trick to counter Semantic Dropdown padding, so that the child link fits the entire dropdown item.
    margin: -0.78571429rem -1.14285714rem !important;
    padding: 0.78571429rem 1.14285714rem !important;
  }

  &-Divider {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &-RoleRow {
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }

  &-RoleCol {
    & > a {
      display: flex !important;
      width: 100%;
      text-decoration: none !important;
      color: #4a4a4a;
    }

    &-img {
      width: 24px;
      height: 24px;
      margin-right: 7px;
    }
  }
}
