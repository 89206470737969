.RightMenu-tickets {
  width: 290px;
  max-width: 290px;
  min-width: 290px;

  & .ActionBttn {
    width: 137px;
    &-widder {
      width: 100%;
    }
  }

  &-bottom-links {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    padding-top: 5px;
    color: #0085ff;
    font-size: 14px;
    font-weight: 700;
    &-action {
      &:hover {
        cursor: pointer;
        filter: brightness(0.9);
      }
    }
  }
}
