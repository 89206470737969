.VoteCounters {
  &-vertical {
    display: flex;
    flex-direction: column;
  }

  &-horizontal {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
  }
}
