.CBCExpertVote {
  background: #e6e4ee;
  margin-bottom: 8px;
  &-memberCol {
    color: #4a4a4a;
    font-family: Lato;
    font-weight: 700;
    line-height: 22px;
    img {
      max-width: 36px;
      min-width: 36px;
      max-height: 36px;
      min-height: 36px;
    }
    &-wrapper {
      display: flex !important;
      column-gap: 16px;
      row-gap: 8px;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  &-voteCol {
    color: #4a4a4a;
    font-family: Lato;
    line-height: 22px;
    &-date {
      color: #898a8d;
    }
    &-description {
      color: #898a8d;
    }
    &-wrapper {
      display: flex;
      column-gap: 16px;
    }
  }
}
