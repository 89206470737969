.WorkplaceProgressLabels {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 4px;
  & span {
    width: 100% !important;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #4a4a4a;
  }
  &-delivered-text {
    color: #898a8d;
    margin-top: 8px;
  }
  &-entry {
    margin-bottom: 8px;
    display: flex;
    column-gap: 8px;
    align-items: flex-start;
    font-family: Lato;
    color: #898a8d;
    font-weight: 400;
    font-size: 12px;
    & img {
      margin-top: 3px;
      width: 16px;
      height: 16px;
    }
    &-title {
      color: #4a4a4a;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.ModalMarkTicketDeliverable {
  padding: 58px;
  color: #4a4a4a;

  &-title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 16px;
    font-weight: 600;
    color: #5c5c5c;
  }
  &-description {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  &-bttn-wrapper {
    display: flex;
    column-gap: 32px;
    margin-top: 32px;
    justify-content: center;
  }
  .ui.button.SignatureButton {
    width: auto;
  }
}

.ModalReviewTicketCompletion {
  padding: 26px 30px;
  color: #4a4a4a;
  & .field {
    line-height: 20px;
    margin-bottom: 8px !important;
  }
  & textarea {
    resize: none !important;
    font-size: 13px;
  }
  .ui.container.InputFieldCharCount {
    margin-top: 0;
  }
  &-title {
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: 600;
    color: #5c5c5c;
  }
  &-bttn-wrapper {
    display: flex;
    column-gap: 32px;
    margin-top: 32px;
  }
  .ui.button.SignatureButton {
    width: auto;
  }
}

.EvaluateCompletionOverlay {
  margin-top: 44px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 44px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4a4a4a;
  &-title {
    color: #5c5c5c;
    font-family: Lato;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.5799999833106995px;
    margin-bottom: 16px;
  }
  &-subtitle {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  &-info-label {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #898a8d;
    margin-bottom: 16px;
  }
  .ui.selection.dropdown {
    width: 69px !important;
  }
  .ui.form .field > label {
    font-weight: 400 !important;
    font-size: 14px;
  }
  textarea {
    resize: none !important;
    height: 55px !important;
  }
}

.TicketEvaluation {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4a4a4a;

  &-header {
    display: flex;
    column-gap: 24px;
    align-items: center;
    img {
      min-width: 56px;
      max-width: 56px;
      min-height: 56px;
      max-height: 56px;
    }
    &-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: #5c5c5c;
    }
    &-text {
      display: flex;
      flex-direction: column;
    }
  }
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #4a4a4a;
  }
  &-small-text {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #898a8d;
    margin-bottom: 8px;
  }
  &-evaluation {
    padding: 13px 21px 13px 17px;
    background-color: #e6e4ee;
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  &-no-notes {
    color: #898a8d;
  }
}
