.MattermostCreateUser {
  margin: auto !important;
  color: #4a4a4a;
  background-color: #f1f0f6 !important;
  width: 760px !important;
  padding: 40px 56px !important;
  &-title {
    text-align: center;
    &-image {
      margin-left: auto;
      margin-right: auto;
      width: 145px;
    }
    h1 {
      margin-top: 8px;
    }
    margin-bottom: 40px;
  }
  &-body {
    color: black !important;
    font-size: 14px !important;
    &-field-label {
      margin-top: -8px;
      color: #4a4a4a;
    }
    &-pwd-hint {
      border-radius: 3px;
      background: white;
      margin: 1em 0 0;
      padding: 0.7em 1em;
      width: 100%;
    }
  }
}
