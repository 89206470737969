$width: 445px;
$height: 440px;
$widthXL: 500px;
$heightXL: 550px;
$widthXXL: 900px;
$heightXXL: 550px;

.Wall {
  width: $width !important;
  height: $height;
  min-width: 290px;
  min-height: $height;
  text-align: center;
  margin: auto !important;

  &.xl {
    width: $widthXL !important;
    height: fit-content;
    padding: 30px;
  }

  &.xxl {
    width: $widthXXL !important;
    height: fit-content;
    min-width: $widthXXL;
    margin: auto !important;
    padding: 20px 30px;
  }

  .WallContent {
    margin: auto;
    width: 250px;

    &.xl {
      width: auto;
    }

    &.xxl {
      width: auto;
      text-align: left;
      display: flex;
      flex-direction: column;

      & .WallContent-title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      & .WallContent-subtitle {
        font-size: 24px;
        font-weight: 700;
      }

      p,
      li {
        font-size: 16px;
      }

      li {
        margin-bottom: 5px;

        & span {
          font-style: italic;
        }
      }

      & .button {
        margin-left: auto;
        margin-right: auto;
      }
    }

    & p,
    & .list,
    &-form {
      text-align: left;
    }

    &-logo {
      width: 50px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }

    &-title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 40px;
    }

    &-subtitle {
      font-size: 34px;
      font-weight: 700;
    }

    &-button {
      color: white;
      background-color: #534c90;
      width: 100%;
    }

    &-extra {
      margin-top: 20px;
    }
  }
  &-email-token-input {
    display: flex;
    align-items: last baseline;
    max-width: 205px;
    label {
      font-weight: 400 !important;
    }
  }

  &-link {
    color: #0085ff;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
}

.MattermostWall {
  margin: auto !important;
  color: #4a4a4a;
  background-color: #f1f0f6 !important;
  width: 750px !important;
  &-header {
    padding: 32px 32px !important;
    text-align: center;
    background-color: white;
    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 16px;
    }
    &-subtitle {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }
  }
  &-body {
    padding: 32px 55px 32px 40px !important;
    padding: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    &-logo {
      > img {
        margin-bottom: 8px !important;
        margin-left: -3px;
      }
    }
    h5 {
      text-transform: uppercase;
    }
    &-description {
      span {
        font-weight: bold;
      }
    }
  }
}
