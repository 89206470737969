.Stepper {
  &-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #000000;

    margin-bottom: 22px;
  }

  &-step {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    & i {
      margin-right: 16px;
      font-size: 16px;
    }

    &.rejected {
      font-weight: bold;
      color: #de0e01;
      & i {
        color: #de0e01 !important;
      }
    }

    &.active {
      font-weight: bold;
      color: #56488c;
      & i {
        color: #56488c !important;
      }
    }
    &.completed {
      color: #3ac358;
      & i {
        color: #3ac458 !important;
      }
    }
    &.disabled {
      color: #898a8d;
      & i {
        color: #bfbfbf !important;
      }
    }
  }
}
