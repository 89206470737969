.EngagementLevelField {
  & input {
    max-width: 64px !important;
  }
  & .ui.input::after {
    content: "Days per month" !important;
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #898a8d;
  }
}
