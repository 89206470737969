.ExpertDetailsVolunteerModal {
  padding: 60px 20px;
  text-align: center;
  &-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #5c5c5c;
    margin-bottom: 36px;
  }
  &-bttns-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    column-gap: 8px;
  }
}
