.GalleryModal {
  &-left-arrow {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 50%;
    left: -5%;
    color: white;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
  &-right-arrow {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 101.4%;
    color: white;
    transform: rotate(180deg);
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
}
