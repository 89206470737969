.SeconderRow {
  &-ProfileCol {
    display: flex !important;
    align-items: flex-end;

    &-avatar {
      width: 57px;
      height: 57px;
      margin-right: 24px;
      margin-bottom: -10px;
    }

    &-Details {
      font-size: 14px;
      line-height: 22px;
      &-fullName {
        font-weight: 700;
        color: #000000;
      }
      &-type {
        font-weight: 400;
        color: #898a8d;
      }
    }
  }

  &-col,
  &-assignedOnCol {
    display: flex !important;
    align-items: flex-end;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #898a8d;

    word-break: break-all;
    word-wrap: break-word;
  }

  &-assignedOnCol {
    justify-content: flex-end;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #2d9131;

    & > button {
      align-self: center;
      color: white !important;
      background: #56488c !important;
    }
  }

  &-ConfirmationModal {
    padding: 55px 85px !important;
    text-align: center !important;

    &-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: -0.58px;
      color: #5c5c5c;
      margin-bottom: 8px;
    }

    &-reminder {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #4a4a4a;
      margin-bottom: 32px;
    }

    &-nextStep {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #4a4a4a;
      margin-bottom: 32px;
    }
  }
}
