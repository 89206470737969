.PreApplicationModal {
  display: flex;
  justify-content: space-between;

  &-left-container {
    background-color: #56488c;
    color: white;
    width: 50%;
    padding: 70px 24px 30px 24px;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    &-title {
      font-size: 30px;
      margin-bottom: 16px;
    }
    &-img-wrapper {
      position: relative;
      > img {
        margin-left: auto;
        margin-right: auto;
      }
      &-avatar {
        position: absolute;
        top: calc(50% - 101px);
        left: calc(50% - 101px);
        > img {
          width: 202px;
          height: 202px;
        }
      }
    }
  }
  &-right-container {
    width: 50%;
    color: #4a4a4a;
    padding: 40px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    &-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
  @media only screen and (max-width: 570px) {
    flex-direction: column;
    .PreApplicationModal-left-container {
      width: 100%;
    }
    .PreApplicationModal-right-container {
      width: 100%;
    }
  }
}
