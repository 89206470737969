.CustomRateField {
  & &-label {
    margin-bottom: 24px !important;
  }

  & &-iconGroup {
    display: flex;
    justify-content: center;

    & img:hover {
      cursor: pointer;
    }

    & img:not(:first-child) {
      margin-left: 20px !important;
    }
  }

  & &-error {
    display: flex;
    justify-content: center;
  }
}
