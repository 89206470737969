.EthereumForm {
  &-titleWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    & img {
      width: 58px;
      height: 58px;
      margin: 40px 0 16px 0;
    }

    & p {
      font-family: Lato;
      font-style: normal;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #4a4a4a;
      margin: 0;
    }
  }

  &-explanation-paragraph {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
    text-align: center;
  }

  &-wallet-row {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px 0;
  }

  &-preWrapper {
    background-color: #f8f8f8;
    padding: 7px 14px;
    & pre {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}
