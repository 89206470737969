.ExpertOnboarding {
  &-breadcrumb {
    h2 {
      margin-bottom: 8px;
    }
    a {
      font-weight: 700;
      color: #0085ff !important;
    }
    &-link {
      margin-top: 8px;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
  &-description {
    margin-bottom: 32px;
  }
  &-empty-list {
    margin-top: 16px;
    max-width: 348px;
    min-width: 348px;
    border: 1px solid #8d84b5;
    border-radius: 4px;
    padding: 24px;
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
      min-height: 122px;
      min-width: 122px;
      margin-bottom: 16px;
    }
    &-title {
      color: #56488c;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }
    &-description {
      color: #8d84b5;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  &-link {
    color: #0085ff !important;
    display: flex;
    column-gap: 8px;
  }
  &-step {
    display: flex;
    column-gap: 16px;
    margin-bottom: 32px;
    &-left {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      &-bar {
        margin-left: 8px;
        margin-right: 8px;
        border-left: 1px solid #56488c;
        height: 100%;
      }
      &-circle {
        min-width: 16px;
        min-height: 16px;
        max-width: 16px;
        max-height: 16px;
        background-color: #56488c;
        border-radius: 8px;
      }
    }
    &-right {
      width: 100%;
      &-wrap {
        display: flex;
        justify-content: space-between;
        &-label {
          color: #8d84b5;
          font-family: Lato;
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0px;
          margin-right: 16px;
          &.secondary {
            color: #56488c;
          }
        }
      }
      &-description {
        color: #56488c;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
      }
      &-list {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 100px;
        overflow-y: hidden;
      }
    }
  }
}
