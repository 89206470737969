$iconWidth: 2em;

.DynamicInputFields {
  &-label {
    margin-bottom: 0 !important;
  }

  & input {
    padding-right: $iconWidth * 2 !important;
  }

  & .IconContainer {
    height: 100%;
    position: absolute;
    right: 0;

    & > .IconContainer-table {
      height: 100%;
      display: table;

      & i {
        display: table-cell;
        line-height: 1;
        vertical-align: middle;
        width: $iconWidth;
        pointer-events: initial;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  // If spans with InputFieldCharCount as children exist,
  // add a margin-bottom to all but the last one of them.
  & > span:not(:last-of-type) > .InputFieldCharCount {
    margin-bottom: 1em;
  }
}
