.CBCExpertApplications {
  &-filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 16px;
    margin-bottom: 40px;
    &-inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .field {
        margin: 0px !important;
      }
    }
  }
}
