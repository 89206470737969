.CustomPriorityItemField {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &-Wrapper {
    display: flex;

    &-indexLabel {
      align-self: center;
      margin-right: 21px;
    }

    &-arrowGroup {
      display: flex;
      flex-direction: column;
      margin-right: 18px;
      justify-content: center;

      & > img {
        width: 14px;
        height: 8px;

        &:first-child {
          margin-bottom: 8px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  & > .ui.red.basic.pointing.label {
    margin-right: auto;
  }
}
