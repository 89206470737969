.PageContent {
  grid-area: content;
  width: 100%;
  &-app {
    padding: 2em 2.5em 1em 2.5em !important;
  }
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #f6f6f6;
  &--cbcPage {
    background-color: #e6e4ee;
  }

  @media only screen and (max-width: 767px) {
    & {
      padding: 14px;
      padding-bottom: 0px;
    }
  }

  @media only screen and (max-width: 991px) {
    & {
      align-content: center;
    }
  }
}

@media only screen and (max-width: 991px) {
  .computer-only {
    display: none !important;
  }
}
