.PreSelectionVoteForm {
  background: #f1f0f6;
  border-top: 2px solid #8d84b5;
  margin-top: 25px !important;
  font-family: Lato;
  font-style: normal;

  & .ui.basic.red.label {
    background: none #f1f0f6 !important;
  }

  &-choice {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-top: 10px;

    & i {
      margin-left: 14px;
    }

    &.Yes {
      color: #2d9131;
    }
    &.No {
      color: #ba1010;
    }
    &.Neutral {
      color: #898a8d;
      & i {
        margin-left: 9px;
      }
    }
  }

  &-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #56488c;
    width: 100%;
  }

  & input,
  textarea,
  .ui.selection.dropdown {
    color: #56488c !important;
    background-color: #f1f0f6 !important;
    border-color: #bbb6d1 !important;
  }

  & label,
  p {
    color: #56488c !important;
  }

  & .VotingSection {
    &-title {
      display: flex;
      justify-content: space-between;
      color: #56488c;

      &-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }

    &-grid {
      display: inline-grid;
      width: 100%;
      border: 1px solid #bbb6d1;
      box-sizing: border-box;
      grid-gap: 1px;
      background-color: #bbb6d1;
    }

    &-row {
      background-color: #f1f0f6;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      column-gap: 8px;

      & p:first-child {
        margin-bottom: 0px;
      }

      & .dropdown.icon {
        color: #0085ff;
      }
    }

    &-scale {
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
    }
  }

  & .TotalScore {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 34px;
    background: #56488c;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: white;
    padding: 0 21px;
  }

  &-success-modal {
    width: 495px !important;
    padding: 40px;

    & img {
      width: 38px !important;
      height: 38px !important;
      margin-bottom: 8px !important;
    }

    & h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.58px;
      color: #5c5c5c;
      margin-bottom: 8px;
    }

    & p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4a4a4a;
    }
  }
}
