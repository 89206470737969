.Collapsible {
  &-Description {
    line-height: 1.4em !important;
  }
  &-Grid {
    display: flex !important;
    justify-content: space-between !important;
  }
  &-LeftColumn {
    padding-top: 1px !important;
    padding-left: 0px !important;
  }
  &-RightColumn {
    padding-top: 1px !important;
    text-align: right;
  }
  &-Content {
    margin-top: 5px !important;
  }
  &-DeleteBttn {
    font-size: 12px !important;
    line-height: 5px !important;
  }
  &-EditableContent {
    float: right !important;
    margin-bottom: 10px !important;
    margin-top: -7px !important;
    padding: 0px !important;
    margin-right: -8px !important;
  }
}

.DraftOptions {
  min-width: 290px;
  max-width: 290px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  &-top-section {
    background-color: #f6f6f6;

    padding: 18px 24px;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    color: #4a4a4a;
  }
  &-bottom-section {
    background-color: white;
    padding: 18px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-link {
      color: #0085ff;
      font-size: 14px;
      font-weight: 700;
      &:hover {
        cursor: pointer;
        filter: brightness(0.9);
      }
    }
  }
}
