.MyAreaPopup {
  border: none !important;
  background-color: #56488c !important;
  width: 162px !important;

  &::before {
    box-shadow: none !important;
    background-color: #56488c !important;
  }

  &-link {
    width: 100%;
    color: #f1f0f6;

    &:hover {
      font-weight: bold;
    }

    &.active {
      cursor: auto;
      font-weight: bold;
      background-color: #383154;
    }

    &.logout {
      margin-top: 8px !important;
      color: #bbb6d1;
    }
  }
}
