.AdminApplications {
  &-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  &-modal {
    padding: 40px;
    color: #5c5c5c;
    &-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
    }
    &-description {
      text-align: center;
    }
    &-bttns {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      column-gap: 8px;
    }
  }
  &-card {
    width: 100%;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    display: flex;
    column-gap: 16px;
    &.transparent {
      background-color: transparent;
      border: 1px solid #bfbfbf;
    }
    &.cbc-vote {
      cursor: pointer;
      &:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }
    &-left-ribbon {
      width: 16px;
      &.dark-red {
        background-color: #fa5659;
      }
      &.yellow {
        background-color: #f4cf0d;
      }
      &.teal {
        background-color: #29d9b9;
      }
      &.blue {
        background-color: #0070d2;
      }
      &.purple {
        background-color: #56488c;
      }
    }
    &-content {
      padding-top: 16px;
      padding-bottom: 16px;
      width: 100%;
      display: flex;
      grid-column-gap: 2%;

      &-column {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        min-width: 20%;
        max-width: 20%;

        &-description {
          display: flex;
          flex-direction: column;
          line-height: 23px;
          &-title {
            color: #4a4a4a;
            font-weight: 700;
          }
          &-info {
            color: #898a8d;
            display: flex;
            &-timestamp {
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }

      &-lastColumn {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding-right: 16px;
        column-gap: 8px;
        row-gap: 8px;
        height: 100%;

        &-label {
          &.dark-red {
            color: #ba1010;
          }
          &.yellow {
            color: #e7aa0c;
          }
          &.teal {
            color: #18c6a6;
          }
          &.blue {
            color: #0070d2;
          }
          &.purple {
            color: #56488c;
          }
        }

        .ui.purple.button {
          background-color: #56488c !important;
          &:hover {
            background-color: #56488c !important;
            filter: brightness(0.95);
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }

        .ui.purple.basic.button {
          background-color: transparent !important;
          color: #56488c !important;
          font-weight: bold;
          -webkit-box-shadow: 0 0 0 1px #56488c inset !important;
          box-shadow: 0 0 0 1px #56488c inset !important;
        }

        .ui.button:hover {
          filter: brightness(0.95);
        }
        &-CBC-content {
          text-align: end;
          display: flex;
          align-items: center;
          column-gap: 8px;
          row-gap: 8px;
          flex-wrap: wrap;
          .ui.button {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.AdminApplicationsFilters {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    &-left {
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 8px;
      margin-bottom: 16px;
    }
    &-right {
      display: flex;
      align-items: center;
      column-gap: 24px;
    }
  }
  &-bottom {
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    transition: max-height 0.18s ease-out, opacity 0.18s ease-out, visibility 0.18s ease-out;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;

    &.visible {
      opacity: 1;
      visibility: visible;

      max-height: 100%;
      margin-bottom: 16px;
    }
  }
  .input {
    width: 300px !important;
  }
  .field {
    margin: 0 !important;
  }
  .FiltersLink {
    cursor: pointer;
    color: #0085ff;
    display: flex;
    column-gap: 8px;
  }
}
