.NomineeVoting {
  &-title {
    width: 50%;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #4a4a4a;
    margin-bottom: 16px;
  }
}
