.ExpertDetailsCBCVotes {
  &-vote-summary {
    &-label {
      color: #4a4a4a;
      font-family: Lato;
      font-size: 12px;
      line-height: 22px;
      margin: 8px 0px;
    }
    &-your-vote {
      width: 100%;
      border-radius: 4px;
      background-color: white;
      padding: 8px;
      color: #4a4a4a;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }
  }
}
