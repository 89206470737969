.AppOverlay {
  background-color: white;
  &-closeIcon {
    position: absolute;
    margin-top: 25px;
    margin-right: 25px;
    top: 0;
    right: 0;
    content: "";
    :hover {
      cursor: pointer;
      filter: brightness(1.2);
    }
    img {
      height: 20px;
      width: 20px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .AppOverlay {
    width: 100% !important;
  }
}

@media only screen and (min-width: 992px) {
  .AppOverlay {
    width: 500px !important;
  }
}
