// Layout colors.
$bodyBackgroundColor: rgb(243, 243, 244);
$layoutColor: rgb(43, 68, 119);
$layoutColor_l1: rgb(57, 90, 159);
$layoutColor_l2: rgb(207, 215, 231);

$brandBlack: #1d1d1b;
$brandBlack_l1: scale-color($brandBlack, $lightness: 20%);
$brandBlack_l2: scale-color($brandBlack, $lightness: 40%);

$brandYellow: #fcc449;
$brandYellow_l1: scale-color($brandYellow, $lightness: 20%);
$brandYellow_l2: scale-color($brandYellow, $lightness: 40%);
$brandYellow_l3: scale-color($brandYellow, $lightness: 70%);
$brandYellow_l4: scale-color($brandYellow, $lightness: 90%);
$brandYellow_d1: scale-color($brandYellow, $lightness: -20%);
$brandYellow_d2: scale-color($brandYellow, $lightness: -40%);

$brandPurple: #7f378c;
$brandPurple_l1: scale-color($brandPurple, $lightness: 20%);
$brandPurple_l2: scale-color($brandPurple, $lightness: 40%);

$brandBlue: #345996;
$brandBlue_l1: scale-color($brandBlue, $lightness: 20%);
$brandBlue_l2: scale-color($brandBlue, $lightness: 40%);
$brandBlue_l3: scale-color($brandBlue, $lightness: 60%);
$brandBlue_d1: scale-color($brandBlue, $lightness: -40%);

$brandGrey: #878686;
$brandGrey_l1: scale-color($brandGrey, $lightness: 20%);
$brandGrey_l2: scale-color($brandGrey, $lightness: 40%);
$brandGrey_l3: scale-color($brandGrey, $lightness: 60%);

$expertOrange: #e13c13;
$expertOrange_l1: scale-color($expertOrange, $lightness: 20%);
$expertOrange_l2: scale-color($expertOrange, $lightness: 40%);

$investorTurquoise: #54c1ea;
$investorTurquoise_l1: scale-color($investorTurquoise, $lightness: 20%);
$investorTurquoise_l2: scale-color($investorTurquoise, $lightness: 40%);

$startupGreen: #57bcb4;
$startupGreen_l1: scale-color($startupGreen, $lightness: 20%);
$startupGreen_l2: scale-color($startupGreen, $lightness: 40%);

$topSearchPurple: #56488c;
$topSearchPurple_d1: scale-color($topSearchPurple, $lightness: -20%);
$leftMenuPurple: #4c2e75;
$leftMenuPurple_d1: scale-color($leftMenuPurple, $lightness: -20%);

// Dimensions.
$leftMenuWidth: 220px;
$leftMenuCollapsedWidth: 70px;
$topSearchHeight: 80px;
$breadcrumbHeight: 80px;

// Transitions.
$leftMenuTransition: width 0.4s, min-width 0.4s;

// Cards dimensions.
$startupCardWidth: 25em;
$startupCardHeight: 36em;

// Other stuff.
$defaultBoxShadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
