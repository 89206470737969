/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: white;
  overflow: hidden;
}

h2,
h3 {
  font-weight: 100;
  color: $brandBlack;
}

.modal-input {
  max-width: 130px;
  &.error {
    input {
      background-color: #fff6f6 !important;
      border-color: #e0b4b4 !important;
      color: #9f3a38 !important;
      font-size: 14px !important;
    }
    i {
      color: #9f3a38 !important;
    }
  }
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formError {
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}

.marginTop5 {
  margin-top: 5px;
}

.marginBot5 {
  margin-bottom: 5px;
}

html {
  scroll-behavior: smooth;
}

.border_gradient {
  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75) !important;
  }
}

.hiddenDiv {
  visibility: hidden;
}

.display-none {
  display: none !important;
}
.font-gray {
  color: gray;
}

.font-black {
  color: black;
}

.font-lightGray {
  color: #afafaf !important;
}

.with-dropShadow {
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
}

.opaque-disabled-field {
  &,
  & label,
  & div.disabled,
  & input {
    opacity: 1 !important;
  }
}

.WrapperSection {
  background: white;
  border-radius: 5px;
  padding: 23px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.HideNumArrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}
