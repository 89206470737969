$globalMarginLeft: 48px;
$globalPaddingLeft: 20px;

.Question {
  padding: 0;
  border-radius: 5px;
  background-color: white;
  border: 0.5px solid #d4d4d5;
  min-width: 344px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-header {
    padding: 14px;
    display: flex;
    align-items: center;
    .header-name {
      color: rgba(0, 0, 0, 0.68);
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.5799999833106995px;
      margin-right: 5px;
      margin-left: 10px;
    }
    .header-date {
      font-size: 12px;
      font-weight: 600;
      color: #898a8d;
    }
    .header-parent-name {
      font-size: 13px;
      font-weight: 600;
      color: #0085ff;
    }
    .header-visibility {
      margin-left: auto;
      align-self: baseline;
    }
    .header-visibility:hover {
      filter: brightness(0.8);
      cursor: pointer;
    }
  }

  &-body {
    padding-left: $globalPaddingLeft;
    padding-right: 14px;
    margin-left: $globalMarginLeft;
    margin-right: 25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.68);

    &-attachments {
      margin-top: 10px;
      margin-left: 65px;
    }
    &-replies {
      align-self: center;
      min-width: 88px;
      margin: 0 15px;
      font-size: 12px;
      font-weight: 600;
      color: #0085ff;
      cursor: pointer;
      > span {
        margin-right: 5px;
      }
      > img {
        display: inline-block !important;
      }
    }
    &-replies:hover {
      filter: brightness(0.7);
    }
  }
  &-divider {
    margin-top: 23px;
    margin-bottom: 27px;
    border-top: 0.5px solid #c7c7c7;
    width: 100%;
  }
  &-input {
    padding-left: $globalPaddingLeft;
    margin-left: $globalMarginLeft;
    margin-right: 25px;
  }
  &-input-reply {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-left: $globalMarginLeft;
    margin-right: 25px;
    margin-bottom: 15px;
  }

  &-submit-bttn.ui.button {
    height: 30px;
    margin-right: 0px;
    line-height: 8px;
    background-color: #56488c;
    color: white;
  }
}

.inputStyle {
  width: 100%;
  height: 100%;
  margin-left: 7px;
  margin-right: 7px;
  > textarea {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom: none !important;
    font-size: 13px !important;
    resize: none !important;
  }
  > div {
    height: 25px;
    padding: 0px 2px;
    display: flex;
    align-items: center;
    font-size: 11px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
    border-top: none;
    .tooltip {
      padding: 0px 6px 3px;
      border-radius: 0.28571429rem;
    }
    &.textAreaBorder {
      border-color: #85b7d9 !important;
    }
    .tooltip:hover {
      border: 1px solid rgba(34, 36, 38, 0.15);
      background-color: #f4f5f6;
      cursor: pointer;
    }
  }
}

.Replies {
  margin-left: 60px;
  margin-right: 25px;
  &-hidden {
    display: none;
  }
  .Replies-header {
    &:not(:first-child) {
      margin-top: 25px;
    }
    height: 35px;
    display: flex;
    justify-content: space-between;
    > img {
      margin-top: -2px;
    }
  }
  .header-name {
    color: rgba(0, 0, 0, 0.68);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5799999833106995px;
    margin-right: 5px;
    &.green {
      color: #36963e;
    }
  }
  .header-name-autor {
    color: #36963e;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5799999833106995px;
  }
  .header-date {
    font-size: 12px;
    font-weight: 600;
    color: #898a8d;
  }
  .header-date-edit {
    font-size: 12px;
    font-weight: 600;
    color: #898a8d;
    margin-right: 35px;
  }
  .header-reply-edit {
    position: absolute;
    left: calc(100% - 50px);
    text-decoration: underline;
  }
  .header-reply-edit:hover {
    cursor: pointer;
    font-weight: 800;
  }
  &-reply {
    margin-left: $globalMarginLeft;
    margin-right: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(0, 0, 0, 0.68);
  }
  &-attachments {
    width: 85%;
    margin-top: 10px;
    margin-left: $globalMarginLeft;
    display: inline-block;
  }
}

.hidden-input {
  border: none;
  .field {
    > input {
      display: none;
    }
  }
}

@media only screen and (max-width: 425px) {
  .Replies {
    margin-left: 20px;
  }
}
