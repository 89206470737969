.VoteIcon {
  width: auto !important;
  height: auto !important;
  &.Upvote {
    color: #2d9031;
  }

  &.Neutral {
    transform: rotate(90deg);
    color: #bfbfbf;
  }

  &.Downvote {
    color: #ba1010;
  }
}

.VoteIconWithCount {
  display: flex;
  column-gap: 4px;

  & > i.icon {
    margin-right: 0 !important;
  }

  & > &-count {
    font-weight: bold;
    font-size: 12px;
    &.Upvote {
      color: #2d9031;
    }

    &.Neutral {
      color: #bfbfbf;
    }

    &.Downvote {
      color: #ba1010;
    }
  }

  &.big > &-count {
    font-size: 1.4em;
  }
}
