.Deliverable {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  &-title {
    font-family: Lato !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    color: #4a4a4a !important;
  }
  &-option {
    color: #898a8d;
  }
}

.Bttn-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .ui.button {
    font-size: 13px;
    height: 26px;
    line-height: 5px;
    margin-left: 5px;
  }
  .ui.button.basic {
    height: 26px;
    line-height: 5px;
    margin-left: 5px;
  }
  .ui.button:hover {
    filter: brightness(1.1);
  }
}
