.ExpertEvaluationForm {
  textarea {
    resize: none !important;
    height: 55px !important;
    max-height: 55px !important;
  }
  &-complete-modal {
    padding: 40px 51px;
    img {
      max-width: 38px;
      max-height: 38px;
      min-width: 38px;
      min-height: 38px;
      margin-inline: auto;
    }
    p {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
    }
    &-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #5c5c5c;
    }
    &-bttns {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
