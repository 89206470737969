$sidePadding: 10px;
$verticalPadding: 5px;

.DocumentBadgeGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.DocumentBadge {
  display: inline-flex;
  justify-content: space-between;
  padding: $verticalPadding $sidePadding;
  border-radius: 5px;
  overflow: hidden;
  background-color: #eaeaea;
  max-width: 200px;
  align-items: center;

  & > img.DocumentBadge-icon {
    width: 10px;
    height: 18px;
  }
  &-filename {
    word-break: break-all;
    border: none;
    background-color: unset;

    &:hover {
      cursor: pointer;
    }
  }
  &-close {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    margin-right: -$sidePadding;
    min-width: 22px;
    height: 150%;

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
      text-decoration: none;
    }
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0.97);
  }
}
