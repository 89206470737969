.Bid-form {
  padding-left: 10px;
  padding-right: 10px;
  textarea {
    min-height: 6em !important;
    height: 6em !important;
  }
  .fields {
    margin-bottom: 25px !important;
  }
  .field {
    margin-bottom: 25px !important;
  }
  .grouped.fields {
    .field {
      margin-bottom: 0px !important;
    }
  }
  .header {
    margin-top: 10px !important;
  }
}
